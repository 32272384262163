import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import { selectErecruiterPipe } from '@mkp/account/state';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

export const erecruiterGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const isErecruiter = inject(Store).pipe(selectErecruiterPipe);
  const vacancyId = route.params['id'] as string;

  return isErecruiter.pipe(
    map((isErecruiter) =>
      isErecruiter ? true : router.createUrlTree([getRedirectRoute(vacancyId)])
    )
  );
};

const getRedirectRoute = (vacancyId?: string) =>
  vacancyId ? `${AppConfig.routes.vacancy}/${vacancyId}/applications` : AppConfig.routes.dashboard;
