import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActionState } from '../../elements';
import { SnackbarConfig } from './snackbar.config';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  readonly snackbar = new Subject<SnackbarConfig>();
  readonly snackbar$: Observable<SnackbarConfig> = this.snackbar.asObservable();

  show(title: string, snackbarConfig: Omit<SnackbarConfig, 'title'> = {}) {
    const config: SnackbarConfig = {
      state: ActionState.Success,
      title,
      ...snackbarConfig,
    };

    this.snackbar.next(config);
  }

  showError(title: string, config?: Omit<SnackbarConfig, 'state'>) {
    this.show(title, { state: ActionState.Error, ...config });
  }
}
