import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PreloadStrategyModuleList } from './preload-strategy';

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      useHash: false,
      scrollPositionRestoration: 'disabled',
      preloadingStrategy: PreloadStrategyModuleList,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
  providers: [PreloadStrategyModuleList],
})
export class AppRoutingModule {}
