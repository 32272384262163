import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import { selectIsInternalATSPipe } from '@mkp/account/state';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

export const internalATSsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const internalATS = inject(Store).pipe(selectIsInternalATSPipe);

  const vacancyId: string = route.params['id'];

  return internalATS.pipe(
    map((internalATS) =>
      internalATS ? true : router.createUrlTree([`${AppConfig.routes.vacancy}/${vacancyId}`])
    )
  );
};
