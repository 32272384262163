import { LegalEntityUpdatePayload } from '@mkp/account/data-access';
import { createActionGroup, props } from '@ngrx/store';
import { QueryOptions } from '@shared/models';

export const claimManagementPageActions = createActionGroup({
  source: 'Claim Management Page',
  events: {
    Load: props<{ query: QueryOptions }>(),
    'Load More': props<{ query: QueryOptions }>(),
    'Select Legal Entity': props<{ legalEntityExternalId: string }>(),
    'Update Legal Entity Status': props<LegalEntityUpdatePayload>(),
  },
});
