import { Injectable } from '@angular/core';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { EntityAction, EntityOp, ofEntityOp, ofEntityType } from '@ngrx/data';
import { Actions, createEffect } from '@ngrx/effects';
import { ENTITY_OPERATION_ERRORS } from '@store/shared.const';
import { tap } from 'rxjs/operators';

@Injectable()
export class ConversationsEffects {
  ngrxDataEffectForQueryAllErrorActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofEntityType('Conversation'),
        ofEntityOp([EntityOp.QUERY_ALL_ERROR]),
        tap(() =>
          // loading of Conversations error
          this.snackBarNotificationsService.show('ERROR_LOADING_CONVERSATIONS', {
            state: ActionState.Error,
          })
        )
      ),
    { dispatch: false }
  );
  ngrxDataEffectForErrorActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofEntityType('Conversation'),
        ofEntityOp([
          ...ENTITY_OPERATION_ERRORS.filter((item) => !item.includes(EntityOp.QUERY_ALL_ERROR)),
        ]),
        tap(() =>
          // generic error message for all Conversations feature errors
          this.snackBarNotificationsService.show('REQUEST_TIMEOUT_MESSAGE', {
            state: ActionState.Error,
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<EntityAction>,
    private snackBarNotificationsService: SnackbarService
  ) {}
}
