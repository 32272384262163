import { CurrencyPipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ExplainBenefitsWrapperComponent } from '@mkp/product/feature-product-benefits';
import { Button, ButtonColor, ButtonComponent, ButtonSize } from '@mkp/shared/ui-library';
import {
  BEST_VALUE_PRODUCTS,
  BEST_VALUE_PRODUCT_TRANSLATIONS,
  Price,
  Product,
  ProductCode,
  ProductFeature,
  ProductOfferingBenefit,
  ProductOfferingPlatform,
  ProductPlatformGroup,
  getPriceForQuantity,
} from '@mkp/shared/util-product';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';
import {
  ProductCalendarLabelComponent,
  ProductPlatformCellComponent,
  ProductTooltipComponent,
} from '../../index';
import { PerformanceReachPipe } from '../pipes/performance-reach.pipe';
import { ProductAlternativeDaysPipe } from '../pipes/product-alternative-days.pipe';
import { ProductAlternativeTypePipe } from '../pipes/product-alternative-type.pipe';

@Component({
  selector: 'mkp-product-card',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CurrencyPipe,
    ButtonComponent,
    DataTestDirective,
    ExplainBenefitsWrapperComponent,
    ProductCalendarLabelComponent,
    ProductTooltipComponent,
    ProductPlatformCellComponent,
    TranslateModule,
    ProductAlternativeDaysPipe,
    ProductAlternativeTypePipe,
    PerformanceReachPipe,
    JsonPipe,
  ],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnInit {
  @Input() product!: Product;
  @Input() platforms!: ProductOfferingPlatform[];
  @Input() benefits!: ProductOfferingBenefit[];
  @Input() isCollapsed = false;
  @Input() isReadMode = false;
  @Input() isLoading = false;
  @Input() productAlternativeUI = false;

  productPrice: Price | undefined;

  @Input()
  set activePlatformGroup(activePlatformGroup: ProductPlatformGroup) {
    if (activePlatformGroup) {
      this.bestValueTranslation = BEST_VALUE_PRODUCT_TRANSLATIONS[activePlatformGroup];
      this.platform = activePlatformGroup;
    }
  }

  @Output() handleSelectedProduct: EventEmitter<Product['code']> = new EventEmitter();
  @Output() handleIsHeaderSticky: EventEmitter<boolean> = new EventEmitter();

  readonly productDetailsConfig: Button = {
    color: ButtonColor.Tertiary,
    size: ButtonSize.Small,
    fullWidth: true,
  };

  selectConfig: Button = {
    color: ButtonColor.Secondary,
    size: ButtonSize.Small,
    fullWidth: true,
  };

  isBestValue = false;
  bestValueTranslation!: string;
  calendarFeature: ProductFeature | undefined;
  platformMapping: ProductOfferingPlatform[] = [];
  platform!: ProductPlatformGroup;

  ngOnInit(): void {
    this.isBestValue = BEST_VALUE_PRODUCTS.includes(this.product.code as ProductCode);
    // We use cmsDiscountPrice only for single product and CMS purpose
    this.productPrice = this.product.cmsDiscountPrice || getPriceForQuantity(this.product, 1);
    this.setFeatures();
  }

  private setFeatures() {
    this.calendarFeature = this.product.features.find(({ id }) => id.includes('calendar'));
    this.platformMapping = this.platforms.filter((platform) =>
      this.product.features.some(({ id }) => id === platform)
    );
  }
}
