import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Vacancy } from '@app/features/vacancy/models';
import { accountTypeaheadActions } from '@app/store/actions';
import { Account } from '@mkp/account/data-access';
import { BoDashboardActions } from '@mkp/user/feature-bo-dashboard/actions';
import { Actions, ofType } from '@ngrx/effects';
import {
  BoDashboardLocalStorageService,
  LocalStorageDashboardItem,
} from './bo-dashboard-localstorage.service';

const MAX_ITEMS_IN_LOCALSTORAGE = 10;

export interface LocalStorageDashboardData {
  accounts: LocalStorageDashboardItem[];
  vacancies: LocalStorageDashboardItem[];
}

@Injectable({
  providedIn: 'root',
})
export class BoDashboardService {
  private readonly boDashboardLocalStorageService = inject(BoDashboardLocalStorageService);
  private readonly selectAccountActions$ = inject(Actions).pipe(
    ofType(
      accountTypeaheadActions.selectAccount,
      BoDashboardActions.selectAccount,
      BoDashboardActions.selectAccountFromVacancy
    ),
    takeUntilDestroyed()
  );
  private limit = MAX_ITEMS_IN_LOCALSTORAGE;

  constructor() {
    this.selectAccountActions$.subscribe(({ account }) => {
      this.addAccount(account);
    });
  }

  addAccount(account: Account) {
    const { accounts } = this.boDashboardLocalStorageService.get();
    this.boDashboardLocalStorageService.save(
      'accounts',
      removeExcessItems(addDashboardItem(mapToDashboardItem(account), accounts), this.limit)
    );
  }

  addVacancy(vacancy: Vacancy) {
    const { vacancies } = this.boDashboardLocalStorageService.get();
    this.boDashboardLocalStorageService.save(
      'vacancies',
      removeExcessItems(addDashboardItem(mapToDashboardItem(vacancy), vacancies), this.limit)
    );
  }
}

const mapToDashboardItem = (data: Account | Vacancy): LocalStorageDashboardItem => ({
  id: data.id,
  createdAt: new Date(),
});
const addDashboardItem = (
  newItem: LocalStorageDashboardItem,
  items: LocalStorageDashboardItem[]
): LocalStorageDashboardItem[] =>
  // add element in array and remove duplicate element if needed
  items.find((item) => item.id === newItem.id)
    ? [newItem, ...items.filter((item) => item.id !== newItem.id)]
    : [newItem, ...items];

const removeExcessItems = (
  items: LocalStorageDashboardItem[],
  limit: number
): LocalStorageDashboardItem[] => items.slice(0, limit);
