interface ERecruiterError {
  error: { message: string };
}

const isERecruiterError = (error: unknown): error is ERecruiterError =>
  (error as ERecruiterError)?.error?.message !== undefined;
const isPartnerAuthenticationFailedError = (error: ERecruiterError): boolean =>
  error.error.message === 'partner user authentication failed';
export const isErecruiterPartnerAuthenticationFailedError = (error: unknown): boolean =>
  isERecruiterError(error) && isPartnerAuthenticationFailedError(error);
