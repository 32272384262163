<section>
  <h1>{{ 'GDPR.COOKIE-DIALOG.TEXT.INTERCOM-LAUNCHER-TITLE' | translate }}</h1>
  <span
    class="body-s"
    [innerHTML]="'GDPR.COOKIE-DIALOG.TEXT.INTERCOM-LAUNCHER-TEXT' | translate | gdprLink"
  ></span>
</section>

<ui-feedback-footer
  [primaryConfig]="gdprBtnConfig"
  primaryDataTest="gdpr-button"
  [secondaryConfig]="cancelBtnConfig"
  secondaryDataTest="cancel-gdpr-button"
  (handlePrimaryClick)="submit()"
  (handleSecondaryClick)="dismiss()"
/>
