import { Document } from '@documents/models/document.model';
import { createAction, props } from '@ngrx/store';
import { FileUpload } from '@shared/models/file-upload.model';

const ActionTypes = {
  RESET_DOCUMENT: '[Document] Reset Document',
  RESET_DOCUMENT_URL: '[Document] Reset Document URL',

  GET_DOCUMENT: '[Document] Get Document',
  GET_DOCUMENT_SUCCEED: '[Document] Get Document Succeed',
  GET_DOCUMENT_FAILED: '[Document] Get Document Failed',

  GET_DOCUMENT_URL: '[Document] Get Document Url',
  GET_DOCUMENT_URL_SUCCEED: '[Document] Get Document Url Succeed',
  GET_DOCUMENT_URL_FAILED: '[Document] Get Document Url Failed',

  UPLOAD_DOCUMENT: '[Document] Upload Document',
  UPLOAD_DOCUMENT_SUCCEED: '[Document] Upload Document Succeed',
  UPLOAD_DOCUMENT_FAILED: '[Document] Upload Document Failed',

  DELETE_DOCUMENT: '[Document] Delete Document',
  DELETE_DOCUMENT_AND_DELETE_UPLOAD: '[Document] Delete Document And Delete Upload',
  DELETE_DOCUMENT_SUCCEED: '[Document] Delete Document Succeed',
  DELETE_DOCUMENT_FAILED: '[Document] Delete Document Failed',

  GET_DOCUMENTS_LIST_SUCCEED: '[Document] Get Documents List Succeed',
  GET_DOCUMENTS_LIST_FAILED: '[Document] Get Documents List Failed',
  GET_DOCUMENTS_FROM_CANDIDATE: '[Document] Get Documents From Candidate ',

  UPDATE_DOCUMENT: '[Document] Udpate Document From Candidate ',
  UPDATE_DOCUMENTS: '[Document] Udpate List of Documents From Candidate ',
  UPDATE_DOCUMENT_SUCCESS: '[Document] Udpate Document From Candidate Success ',
  UPDATE_DOCUMENT_FAILED: '[Document] Udpate Document From Candidate Failed ',

  PARSE_CV: '[Document] Parse CV',
};

/************* Reset Document ***************/
export const ResetDocument = createAction(ActionTypes.RESET_DOCUMENT);

/************* Get Document ***************/
export const GetDocument = createAction(ActionTypes.GET_DOCUMENT, props<{ id: string }>());

export const GetDocumentSuccess = createAction(
  ActionTypes.GET_DOCUMENT_SUCCEED,
  props<{ document: Document }>()
);

export const GetDocumentFailure = createAction(
  ActionTypes.GET_DOCUMENT_FAILED,
  props<{ payload: unknown }>()
);

export const GetDocumentUrl = createAction(ActionTypes.GET_DOCUMENT_URL, props<{ id: string }>());

export const GetDocumentUrlSuccess = createAction(
  ActionTypes.GET_DOCUMENT_URL_SUCCEED,
  props<{ selectedId: string }>()
);

export const GetDocumentUrlFailure = createAction(
  ActionTypes.GET_DOCUMENT_URL_FAILED,
  props<{ payload: unknown }>()
);

export const UploadDocumentSuccess = createAction(
  ActionTypes.UPLOAD_DOCUMENT_SUCCEED,
  props<{ document: Document }>()
);

/************* Delete Document ***************/
export const DeleteDocument = createAction(ActionTypes.DELETE_DOCUMENT, props<{ id: string }>());

export const DeleteDocumentAndDeleteUpload = createAction(
  ActionTypes.DELETE_DOCUMENT_AND_DELETE_UPLOAD,
  props<{ fileUpload: FileUpload }>()
);

export const DeleteDocumentSuccess = createAction(
  ActionTypes.DELETE_DOCUMENT_SUCCEED,
  props<{ id: string }>()
);

export const DeleteDocumentFailure = createAction(
  ActionTypes.DELETE_DOCUMENT_FAILED,
  props<{ payload: unknown }>()
);

/************* Get All ***************/
export const GetDocumentsFromCandidate = createAction(
  ActionTypes.GET_DOCUMENTS_FROM_CANDIDATE,
  props<{ candidateId: string }>()
);

export const GetDocumentsSuccess = createAction(
  ActionTypes.GET_DOCUMENTS_LIST_SUCCEED,
  props<{ documents: Document[] }>()
);

export const GetDocumentsFailure = createAction(
  ActionTypes.GET_DOCUMENTS_LIST_FAILED,
  props<{ payload: unknown }>()
);

/************* Update ***************/
export const UpdateDocuments = createAction(
  ActionTypes.UPDATE_DOCUMENTS,
  props<{ documents: Document[] }>()
);

export const ParseCv = createAction(
  ActionTypes.PARSE_CV,
  props<{ documentId: string; candidateId: string }>()
);

export const UpdateDocumentSuccess = createAction(
  ActionTypes.UPDATE_DOCUMENT_SUCCESS,
  props<{ document: Document }>()
);

export const UpdateDocumentFailure = createAction(
  ActionTypes.UPDATE_DOCUMENT_FAILED,
  props<{ payload: unknown }>()
);
