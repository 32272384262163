import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, OnDestroy, PLATFORM_ID, RendererFactory2, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare global {
  interface Window {
    Intercom?: ((command: string, ...args: unknown[]) => void) & { booted?: boolean };
  }
}

@Injectable({ providedIn: 'root' })
export class Intercom implements OnDestroy {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly renderer = inject(RendererFactory2).createRenderer(document, null);
  private readonly launcherElementSource = new BehaviorSubject<HTMLElement | undefined>(undefined);
  readonly launcherElement$ = this.launcherElementSource.asObservable();

  // the intercom launcher has two elements: the initial one, and another one after opening the chat window
  //  - the initial launcher has class .intercom-lightweight-app
  //  - after opening and closing the chat window the launcher is inside #intercom-container
  private readonly observer = new MutationObserver((mutations) => {
    mutations.some((record) => {
      if (record.type === 'childList' && record.addedNodes.length > 0) {
        const newLauncherElement = Array.from(record.addedNodes)
          .filter(isElementNode)
          .find(isIntercomLauncher);

        if (newLauncherElement) {
          this.launcherElementSource.next(newLauncherElement);
        }
      }
    });
  });

  constructor() {
    const body = inject(DOCUMENT).querySelector('body');
    if (body) {
      this.observer.observe(body, { childList: true });
    }
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  update(data: unknown): void {
    this.execute('update', data);
  }

  show(): void {
    this.execute('show');
  }

  hide(): void {
    this.execute('hide');
  }

  addClass(className: string, launcherElement: HTMLElement): void {
    this.renderer.addClass(launcherElement, className);
  }

  removeClass(className: string, launcherElement: HTMLElement): void {
    this.renderer.removeClass(launcherElement, className);
  }

  private execute(command: string, ...args: unknown[]): void {
    if (!isPlatformBrowser(this.platformId) || !window.Intercom) {
      return;
    }

    window.Intercom(command, ...args);
  }
}

const isElementNode = (node: Node): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE;
const isIntercomLauncher = (element: HTMLElement): boolean =>
  element.classList.contains(`intercom-lightweight-app`) || element.id === `intercom-container`;
