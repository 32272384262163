import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IntercomService } from '@core/services/intercom.service';
import { gdprModalActions } from '@mkp/gdpr/state/actions';
import { GdprModalService } from '../gdpr-modal.service';

@Component({
  selector: 'ui-gdpr-intercom-placeholder',
  templateUrl: 'gdpr-intercom-placeholder.template.html',
  styleUrl: 'gdpr-intercom-placeholder.style.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // this rule is deprecated and should be removed in future versions of angular-eslint
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'intercomMode()',
  },
})
export class GdprIntercomPlaceholderComponent {
  private readonly intercomService = inject(IntercomService);
  private readonly gdprModalService = inject(GdprModalService);
  readonly intercomMode = toSignal(this.intercomService.intercomMode$);

  openModal() {
    this.gdprModalService
      .openGdprFunctionalDialog(gdprModalActions.setGdprFromIntercomPlaceholder)
      .subscribe(({ functional }) => {
        if (functional) {
          this.intercomService.show();
        }
      });
  }
}
