import { createSelector } from '@ngrx/store';
import { selectors } from '@store/reducers/settings.reducer';

const { selectFeatureState } = selectors;

export const selectInterfaceLanguage = createSelector(
  selectFeatureState,
  ({ language }) => language
);

export const selectIntercomHash = createSelector(
  selectFeatureState,
  ({ intercomHash }) => intercomHash
);

export const selectApplicationLanguagePrefix = createSelector(
  selectInterfaceLanguage,
  (lang) => lang && lang.split('-')[0]
);
