<ng-container *ngIf="product">
  <!-- old design -->
  <ng-container *ngIf="!productAlternativeUI; else alternativeUi">
    <div class="product-best-value-wrapper">
      <div
        class="product-best-value"
        [class]="platform + '-background alternative'"
        [innerHTML]="isBestValue ? (bestValueTranslation | translate) : ''"
      ></div>
    </div>
    <div
      class="card"
      [class]="platform + '-border alternative'"
      [class.product-best-value-card-background]="isBestValue"
    >
      <section>
        <span class="product-title title-s">{{ product.name | translate }}</span>
        <div class="calendar-feature-wrapper" *ngIf="calendarFeature">
          <mkp-product-calendar-label
            [productCode]="product.code"
            [title]="calendarFeature.translation"
          />
          <mkp-product-tooltip
            *ngIf="calendarFeature.description"
            [text]="calendarFeature.description"
          />
        </div>
      </section>

      <ng-container *ngIf="!isCollapsed">
        <div *ngIf="!productAlternativeUI" class="product-description body-s">
          {{ product.description | translate }}
        </div>
        <section>
          <!-- if new ui free products and benefits removed-->
          <span class="product-price title-s" *ngIf="productAlternativeUI; else oldUIDesign">
            <span class="nobold"
              >{{ productPrice?.currency }}
              {{ productPrice?.current / 100 | currency: ' ' : 'code' : '1.0-0' : 'fr-CH' }}
            </span>
          </span>
          <!-- else -->
          <ng-template #oldUIDesign>
            <span class="product-price title-s">
              <span class="nobold">{{ productPrice?.currency }} </span>
              {{ productPrice?.current / 100 | currency: ' ' : 'code' : '1.0-0' : 'fr-CH' }}
            </span>
          </ng-template>
          <!-- end -->
          <div class="product-visibility mobile-only">
            <span class="product-visibility-text body-xs"
              >{{ 'PRODUCT_OFFERING.VISIBILITY' | translate }}:</span
            >
            <div class="platform-list">
              <mkp-product-platform-cell
                *ngFor="let platform of platformMapping"
                [platform]="platform"
              />
            </div>
          </div>
          <mkp-explain-benefits-wrapper
            [product]="product"
            [isReadMode]="isReadMode"
            (handleSelectProduct)="handleSelectedProduct.emit(product.code)"
          >
            <ui-button
              class="product-details-btn"
              [config]="productDetailsConfig"
              [mkpDataTest]="'product-details-cta-' + product.code"
              [trackingDetails]="product.code"
              >{{ 'PRODUCT_OFFERING.PRODUCT_DETAILS' | translate }}</ui-button
            >
          </mkp-explain-benefits-wrapper>
        </section>
      </ng-container>

      <ui-button
        *ngIf="!isReadMode"
        class="select-btn"
        [loading]="isLoading"
        (click)="handleSelectedProduct.emit(product.code)"
        [config]="selectConfig"
        mkpDataTest="select-cta-btn"
      >
        {{ 'PRODUCT_OFFERING.SELECT_PLAN' | translate }}
      </ui-button>
    </div>
  </ng-container>
  <ng-template #alternativeUi>
    <div class="product-best-value-wrapper">
      <div
        class="product-best-value"
        [class]="platform + '-background alternative'"
        [innerHTML]="isBestValue ? (bestValueTranslation | translate) : ''"
      ></div>
    </div>
    <div
      class="card"
      [class]="platform + '-border alternative'"
      [class.product-best-value-card-background]="isBestValue"
    >
      <section>
        <span class="product-title title-s">{{ product.name | translate }}</span>
      </section>
      <section class="product-performance">
        <span
          class="product-price title-s"
          *ngIf="(product.code | performanceReachPipe | translate)?.length > 0"
        >
          {{ product.code | performanceReachPipe | translate }}
          <br />
          <span class="alternative-font-xs">{{ 'PERFORMANCE_REACH_LABEL' | translate }}</span>
        </span>
      </section>
      <section *ngIf="calendarFeature && calendarFeature.translation">
        <div
          class="calendar-feature"
          *ngIf="calendarFeature.translation | translate as translatedCalendarFeature"
        >
          <div class="calendar-feature-days">
            {{ translatedCalendarFeature | productAlternativeDays }}
          </div>
          <div class="calendar-feature-type">
            {{ translatedCalendarFeature | productAlternativeType }}
          </div>
        </div>
      </section>
      <ng-container *ngIf="!isCollapsed">
        <section>
          <!-- if new ui free products and benefits removed-->
          <span class="product-price title-s">
            {{ productPrice?.currency }}
            {{ productPrice?.current / 100 | currency: ' ' : 'code' : '1.0-0' : 'fr-CH' }}
            <br />
            &nbsp;
          </span>
          <div class="product-visibility mobile-only">
            <span class="product-visibility-text body-xs"
              >{{ 'PRODUCT_OFFERING.VISIBILITY' | translate }}:</span
            >
            <div class="platform-list">
              <mkp-product-platform-cell
                *ngFor="let platform of platformMapping"
                [platform]="platform"
              />
            </div>
          </div>
        </section>
      </ng-container>

      <ui-button
        *ngIf="!isReadMode"
        [class]="platform + '-call-to-action select-btn'"
        [class.is-best-value-btn]="isBestValue"
        [loading]="isLoading"
        (click)="handleSelectedProduct.emit(product.code)"
        [config]="selectConfig"
        mkpDataTest="select-cta-btn"
      >
        {{ 'PRODUCT_OFFERING.SELECT_PLAN' | translate }}
      </ui-button>
      <br />
      <mkp-explain-benefits-wrapper
        [product]="product"
        [isReadMode]="isReadMode"
        (handleSelectProduct)="handleSelectedProduct.emit(product.code)"
      >
        <ui-button
          class="product-details-btn"
          [config]="productDetailsConfig"
          [mkpDataTest]="'product-details-cta-' + product.code"
          [trackingDetails]="product.code"
          >{{ 'PRODUCT_OFFERING.PRODUCT_DETAILS_ALTERNATIVE' | translate }}</ui-button
        >
      </mkp-explain-benefits-wrapper>
    </div>
  </ng-template>
  <!-- alertnative design -->
</ng-container>
