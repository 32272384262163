import * as fromCompany from '@company/store/effects';
import { BenefitsEffects } from './benefits.effects';
import { IntercomEffects } from './intercom.effects';
import { LanguageEffects } from './language.effects';
import { RouterEffects } from './router.effects';
import { SeoEffects } from './seo.effects';
import { SettingsEffects } from './settings.effects';
import { UploadEffects } from './upload.effects';
import { authEffects, AuthApiEffects } from '@mkp/auth/state';

export const effects = [
  authEffects,
  RouterEffects,
  SettingsEffects,
  ...fromCompany.effects,
  BenefitsEffects,
  UploadEffects,
  SeoEffects,
  AuthApiEffects,
  LanguageEffects,
  IntercomEffects,
];
