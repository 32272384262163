import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { selectGdprFunctional } from '@mkp/gdpr/state';
import { gdprModalActions } from '@mkp/gdpr/state/actions';
import { GdprModalService } from '@mkp/gdpr/ui';
import { TrackingActions } from '@mkp/tracking/state/actions';
import { Store } from '@ngrx/store';
import { configureScope } from '@sentry/browser';
import { State } from '@store/reducers';
import { selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { filter, fromEvent, map, merge, switchMap, take } from 'rxjs';
import { authActions } from '@mkp/auth/actions';

@Component({
  selector: 'mkp-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  offline$ = getOfflineObservable();
  gdprFunctional$ = this.store.select(selectGdprFunctional);

  constructor(
    private store: Store<State>,
    public gdprModalService: GdprModalService
  ) {
    this.store.dispatch(authActions.appBootstrapped());

    // for Gdpr init: we display either a modal or a banner, the banner is set in the template
    this.showGdprInitModal();
  }

  ngOnInit() {
    this.setupSentry();
    this.store.dispatch(TrackingActions.loadTealiumStart());
  }

  onShowBanner() {
    this.gdprModalService.saveShowBannerCookie();
    this.store.dispatch(
      gdprModalActions.initGdprFromBanner({ gdpr: { functional: true, marketing: true } })
    );
  }

  onCloseBanner() {
    this.gdprModalService.saveCloseBannerCookie();
  }

  private setupSentry(): void {
    this.store
      .select(selectLoggedInUser)
      .pipe(filter(Boolean), take(1))
      .subscribe(({ id, language }) => configureScope((scope) => scope.setUser({ id, language })));
  }

  private showGdprInitModal() {
    this.gdprModalService.gdprInitDisplay$
      .pipe(
        take(1),
        filter((gdprInitDisplay) => gdprInitDisplay === 'modal'),
        switchMap(() =>
          this.gdprModalService.openGdprDialog({
            action: gdprModalActions.initGdprFromModal,
            fullDetails: false,
          })
        )
      )
      .subscribe();
  }
}

const getOfflineObservable = () =>
  merge(fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(
    map(() => !navigator.onLine)
  );
