<ng-container
  *ngrxLet="{
    isStickyHeaderVisible: isStickyHeaderVisible$,
    showLabel: showLabel$
  } as vm"
>
  <mkp-product-sticky-header
    *ngIf="!hideStickyHeader && vm.isStickyHeaderVisible && !productAlternativeUI"
    [isProductLoading]="isProductLoading"
    [products]="products"
    [activePlatformGroup]="activePlatformGroup"
    [showLabel]="vm.showLabel"
    [isReadMode]="isReadMode"
    [scrollConfig]="scrollConfig"
    (handleSelectedProduct)="productSelected.emit($event)"
  />

  <div
    class="table-layout product-offering-layout"
    [class.layout-product-alternative-ui]="productAlternativeUI"
    #tableLayout
  >
    <div
      class="table-column-header-placeholder product-offering-placeholder"
      [class.left-shadow]="tableLayout.scrollLeft > 0"
      mkpInViewportNotifier
      [rootMargin]="-100"
      (visibilityChange)="this.isStickyHeaderVisible$.next(!$event)"
    >
      <div *ngIf="tableLayout.scrollLeft > 0" class="scroll-placeholder scroll-placeholder-left">
        <ui-button
          mkpDataTest="scroll-products-left-btn"
          [config]="scrollConfig.left"
          mkpScrollToNext
          [scrollableElement]="tableLayout"
          [itemsWrapperElement]="itemsWrapper"
          position="left"
          [length]="products?.length"
          (click)="productScrolled.emit('left')"
        >
          <ui-asset name="arrow_left" />
          <span *ngIf="vm.showLabel">{{ 'PRODUCT_OFFERING.TABLE.SCROLL' | translate }}</span>
        </ui-button>
      </div>
    </div>
    <div class="table-column-headers" #itemsWrapper [class.center-column]="products?.length === 1">
      <ng-container *ngIf="products?.length; else skeleton">
        <mkp-product-card
          *ngFor="let product of products"
          [isLoading]="product.code === isProductLoading"
          [isReadMode]="isReadMode"
          [isCollapsed]="false"
          [product]="product"
          [platforms]="platforms"
          [benefits]="benefits"
          [productAlternativeUI]="productAlternativeUI"
          [activePlatformGroup]="activePlatformGroup"
          (handleSelectedProduct)="productSelected.emit($event)"
        />
      </ng-container>
      <ng-template #skeleton>
        <mkp-cards-skeleton />
      </ng-template>
    </div>
    <div
      *ngIf="!productAlternativeUI"
      class="table-row-headers product-offering-placeholder"
      [class.left-shadow]="tableLayout.scrollLeft > 0"
    >
      <div *ngIf="platforms" class="table-row-header title title-xxs">
        {{ 'PRODUCT_OFFERING.PLATFORMS' | translate }}
      </div>
      <ng-container *ngIf="platforms; else platformSkeleton">
        <mkp-product-platform-cell
          *ngFor="let platform of platforms"
          class="table-row-header"
          [platform]="platform"
          [showTooltip]="true"
        />
      </ng-container>
      <ng-template #platformSkeleton>
        <mkp-product-table-skeleton />
      </ng-template>
      <div *ngIf="benefits" class="table-row-header title title-xxs">
        {{ 'PRODUCT_OFFERING.BENEFITS' | translate }}
      </div>
      <ng-container *ngIf="benefits; else benefitsSkeleton">
        <mkp-product-table-row-header-feature
          *ngFor="let benefit of benefits"
          class="table-row-header"
          [benefit]="benefit"
          [showTooltip]="true"
        />
      </ng-container>
      <ng-template #benefitsSkeleton>
        <mkp-cards-skeleton />
      </ng-template>
      <div class="table-row-header-placeholder"></div>
    </div>
    <div
      *ngIf="!productAlternativeUI"
      [class.center-column]="products?.length === 1"
      class="table-columns product-offering-columns"
      [class.skeleton-columns]="!products"
    >
      <ng-container *ngIf="products?.length; else columnSkeleton">
        <mkp-product-table-column
          *ngFor="let product of products"
          [product]="product"
          [platforms]="platforms"
          [benefits]="benefits"
          [activePlatformGroup]="activePlatformGroup"
        />
      </ng-container>
      <ng-template #columnSkeleton>
        <mkp-product-table-column-skeleton *ngFor="let item of [].constructor(3)" />
      </ng-template>
    </div>
    <div
      *ngIf="!hideStickyHeader"
      class="table-column-footer-placeholder product-offering-placeholder"
      [class.left-shadow]="tableLayout.scrollLeft > 0"
    ></div>
  </div>
  <section
    class="scroll-placeholder-wrapper"
    [class.scroll-placeholder-wrapper-background]="hideStickyHeader"
  >
    <div
      *ngIf="tableLayout.scrollWidth - tableLayout.offsetWidth > tableLayout.scrollLeft"
      class="scroll-placeholder scroll-placeholder-right"
    >
      <ui-button
        mkpScrollToNext
        mkpDataTest="scroll-products-right-btn"
        [config]="scrollConfig.right"
        [scrollableElement]="tableLayout"
        [itemsWrapperElement]="itemsWrapper"
        [length]="products?.length"
        (click)="productScrolled.emit('right')"
      >
        <ui-asset name="arrow_right" />
        <span *ngIf="vm.showLabel">{{ 'PRODUCT_OFFERING.TABLE.SCROLL' | translate }}</span>
      </ui-button>
    </div>
  </section>
</ng-container>
