import { Conversation } from '../models/conversation.model';

export class ConversationSerializer {
  static fromJson(json: any): Conversation {
    return new Conversation(json);
  }

  static fromJsonArray(jsonArray: any[]): Conversation[] {
    return jsonArray.map((object: any) => ConversationSerializer.fromJson(object));
  }
}
