import { HttpErrorResponse } from '@angular/common/http';
import { Document } from '@mkp/document/data-access';
import { createActionGroup, props } from '@ngrx/store';

export const documentApiActions = createActionGroup({
  source: 'Document Api',
  events: {
    'Documents Loaded Success': props<{ documents: Document[] }>(),
    'Documents Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Documents Reloaded Success': props<{
      url: string;
      documents: Document[];
      originalUrl: string;
    }>(),
    'Documents Download Reloaded Success': props<{
      downloadUrl: string;
      documents: Document[];
      documentName: string;
    }>(),

    'Documents Reloaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Documents Download Reloaded Failure': props<{ errorMessage: HttpErrorResponse }>(),

    'Document View Success': props<{ url: string }>(),
    'Document Download Success': props<{ documentFile: Blob; documentName: string }>(),

    'Document View Again Success': props<{ url: string }>(),
    'Document View Again Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Document View Failure': props<{ errorMessage: HttpErrorResponse }>(),

    'Document Download Again Success': props<{ documentFile: Blob; documentName: string }>(),
    'Document Download Again Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Document Download Failure': props<{ errorMessage: HttpErrorResponse }>(),

    'Document View Original Link Success': props<{ url: string }>(),
    'Document View Original Link Failure': props<{ errorMessage: HttpErrorResponse }>(),

    'Document View Unauthorized': props<{
      documentId: string;
      applicationId: string;
    }>(),
    'Document Not Found': props<{
      url: string;
    }>(),
    'Document Unprocessable': props<{
      url: string;
    }>(),

    'Document Download Unauthorized': props<{
      documentId: string;
      applicationId: string;
    }>(),

    'Documents Deleted Success': props<{ applicationId: string; applicationFullName: string }>(),
    'Documents Deleted Failure': props<{
      applicationId: string;
      errorMessage: HttpErrorResponse;
    }>(),
  },
});
