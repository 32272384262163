import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '@app/features/user/shared/models';
import { State } from '@app/store';
import { selectGdprFunctional } from '@mkp/gdpr/state';
import { gdprModalActions } from '@mkp/gdpr/state/actions';
import { GdprModalService } from '@mkp/gdpr/ui';
import { Intercom } from '@mkp/intercom/util';
import { TealiumService } from '@mkp/tracking/feature-tealium';
import { Store } from '@ngrx/store';
import {
  AsyncSubject,
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  map,
  Observable,
  of,
  takeWhile,
  timer,
} from 'rxjs';
import { filter, share, switchMap, take, tap } from 'rxjs/operators';

export enum IntercomMode {
  AtsDetails = 'ats-details',
}

// this data must correspond with intercom's standard user / company data: https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#section-mapping-of-javascript-attributes-to-intercom-dashboard-and-api
export interface IntercomUserData {
  email: string;
  name: string;
  phone: string;
  user_hash: string;
  company?: IntercomCompanyData;
  language_override?: string;
  user_type: string;
}

export interface IntercomCompanyData {
  company_id: string;
  created_at?: number;
  name: string;
  monthly_spend?: number;
  plan?: string;
  size?: number;
  website?: string;
  industry?: string;
}

export enum IntercomCustomBot {
  IntercomCustomBotClaimCompany = 'intercom-chatbot-trigger-company-claim',
  IntercomCustomBotUpgradeVacancy = 'intercom-chatbot-trigger-upgrade-vacancy',
  IntercomCustomBotCreditInvoice = 'intercom-chatbot-trigger-credit-invoice',
}

type IntercomBotGdprAction =
  | typeof gdprModalActions.setGdprForIntercomBotInClaimCompany
  | typeof gdprModalActions.setGdprForIntercomBotInVacancyList
  | typeof gdprModalActions.setGdprForIntercomBotInVacancyPageHeader
  | typeof gdprModalActions.setGdprForIntercomBotInCreditInvoice;

@Injectable({ providedIn: 'root' })
export class IntercomService {
  private readonly intercomModeSource = new BehaviorSubject<IntercomMode | undefined>(undefined);
  readonly intercomMode$ = this.intercomModeSource.asObservable();

  setIntercomMode(mode: IntercomMode | undefined): void {
    this.intercomModeSource.next(mode);
  }

  /**
   * @see TealiumService.utag$
   * @private
   */
  private readonly isBooted$: Observable<boolean> = timer(0, 500).pipe(
    map((i) => ({
      i,
      isBooted: hasIntercomBootedProperty(this.window) && this.window.Intercom.booted,
    })),
    takeWhile(({ i, isBooted }) => !isBooted && i < 5, true),
    map(({ isBooted }) => isBooted),
    // use share operator to prevent multiple timers on multiple subscriptions
    share({
      // use `AsyncSubject` as a connector to emit the value only when the source observable completes
      connector: () => new AsyncSubject(),
    })
  );

  constructor(
    private readonly window: Window,
    private readonly intercom: Intercom,
    private readonly store: Store<State>,
    private readonly gdprModalService: GdprModalService,
    private readonly tealiumService: TealiumService
  ) {
    this.updateIntercomClassesFromMode();
  }

  /**
   * Show the Intercom messenger.
   * If no conversations it opens with the new message view,
   * otherwise opens with the message list.
   */
  show(): Promise<void> {
    return this.waitForIsBootedThen(() => {
      this.intercom.update({
        hide_default_launcher: false,
      });
      this.intercom.show();
    });
  }

  /**
   * Hide the launcher of the messenger.
   */
  hideLauncher(): Promise<void> {
    return this.waitForIsBootedThen(() => {
      this.intercom.hide();
      this.intercom.update({
        hide_default_launcher: true,
      });
    });
  }

  /**
   * Show the launcher of the messenger.
   */
  showLauncher(): Promise<void> {
    return this.waitForIsBootedThen(() => {
      this.intercom.update({
        hide_default_launcher: false,
      });
    });
  }

  /**
   * Update the data Intercom messenger can use.
   * Calling the update method with a JSON object of user details will only update those fields
   * @param data
   */
  update(data: Partial<IntercomUserData>): Promise<void> {
    return this.waitForIsBootedThen(() => this.intercom.update(data));
  }

  /**
   * Method for opening a custom intercom bot.
   * It checks if the functional cookie are enabled in order to start the bot conversation.
   * @param bot
   * @param action
   * @see injectCustomBotAndOpen
   */
  openCustomIntercomBot(bot: IntercomCustomBot, action: IntercomBotGdprAction) {
    this.store
      .select(selectGdprFunctional)
      .pipe(
        take(1),
        switchMap((hasFunctional) =>
          !hasFunctional
            ? this.gdprModalService
                .openGdprFunctionalDialog(action)
                .pipe(map(({ functional }) => functional))
            : of(true)
        ),
        filter((hasFunctional) => !!hasFunctional)
      )
      .subscribe(() => {
        this.injectCustomBotAndOpen(bot);
      });
  }

  /**
   * Strange edge case:
   * if the app starts with functional cookies disabled and we enable them:
   * the launcher won't show until we force it with this
   */
  forceShowLauncher(): Promise<void> {
    return this.waitForIsBootedThen(() =>
      this.tealiumService.track('link', { event_name: 'page_view' })
    );
  }

  /**
   * Quick helper to ease the readability.
   * @param callback
   * @private
   */
  private waitForIsBootedThen(callback: () => void): Promise<void> {
    return lastValueFrom(
      this.isBooted$.pipe(
        tap((isBooted) => isBooted && callback()),
        map(() => undefined)
      )
    );
  }

  private updateIntercomClassesFromMode(): void {
    // wait for intercom boot
    this.isBooted$
      .pipe(
        takeUntilDestroyed(),
        // listen to changes in mode but also changes in the intercom launcher element
        switchMap(() => combineLatest([this.intercomMode$, this.intercom.launcherElement$])),
        filter(launcherElementIsDefined)
      )
      .subscribe(([intercomMode, launcherElement]) => {
        if (intercomMode === undefined) {
          this.removeAllIntercomClasses(launcherElement);
        } else {
          this.intercom.addClass(intercomMode, launcherElement);
        }
      });
  }

  private removeAllIntercomClasses(launcherElement: HTMLElement): void {
    Object.values(IntercomMode).forEach((className) =>
      this.intercom.removeClass(className, launcherElement)
    );
  }

  /**
   * Generic method to inject an anchor element, get it tagged (addEventListener)
   * by the Intercom script, and be executed (clicked).
   * This eases the possibility of having several bots in our application.
   * Relies on the class name defined on Intercom side.
   * @param customTrigger
   * @private
   */
  private injectCustomBotAndOpen(customTrigger: IntercomCustomBot): HTMLAnchorElement {
    const a = document.createElement('a');
    a.addEventListener = (type: 'click', listener: EventListener) => {
      if (type === 'click') {
        listener(new Event('click'));
        a.remove();
      }
    };
    a.className = customTrigger;
    document.body.appendChild(a);

    return a;
  }
}

const hasIntercomBootedProperty = (
  window: Window
): window is Window & {
  Intercom: { booted: boolean };
} => window?.Intercom?.booted !== undefined;

export function mapUserAndSettingsToIntercomUser(
  user: User,
  intercomHash: string
): IntercomUserData {
  return {
    email: user.email,
    phone: user.phoneNumber ?? '',
    name: `${user.firstName} ${user.lastName}`,
    user_type: user.settings.displayType,
    user_hash: intercomHash,
    language_override: user.language,
  };
}

const launcherElementIsDefined = (
  array: [IntercomMode | undefined, HTMLElement | undefined]
): array is [IntercomMode | undefined, HTMLElement] => array[1] !== undefined;
