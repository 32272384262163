import { Action } from '@ngrx/store';
import { UpdateStr } from '@ngrx/entity/src/models';
import { FileUpload } from '@shared/models/file-upload.model';

export enum ActionTypes {
  UPLOAD_RESET = '[File Upload] Reset',
  UPLOAD_REQUEST = '[File Upload] Request',
  UPLOAD_STARTED = '[File Upload] Started',
  UPLOAD_PROGRESS = '[File Upload] Progress',
  UPLOAD_FAILURE = '[File Upload] Failure',
  UPLOAD_COMPLETED = '[File Upload] Success',
  UPLOAD_ADD_FAILED = '[File Upload] Add Failed',
  UPLOAD_FILE_DELETE = '[File Upload] Delete File',
  UPLOAD_NOOP = '[File Upload] NOOP',
}

export class UploadReset implements Action {
  readonly type = ActionTypes.UPLOAD_RESET;
}

export class UploadRequest implements Action {
  readonly type = ActionTypes.UPLOAD_REQUEST;
  constructor(
    public payload: {
      file: FileUpload;
      isDocument: boolean;
      endpoint: string;
      queryParams: string;
      removeFile: boolean;
    }
  ) {}
}

export class UploadStarted implements Action {
  readonly type = ActionTypes.UPLOAD_STARTED;
  constructor(public payload: UpdateStr<FileUpload>) {}
}

export class UploadAddFailed implements Action {
  readonly type = ActionTypes.UPLOAD_ADD_FAILED;
  constructor(public payload: FileUpload) {}
}

export class UploadProgress implements Action {
  readonly type = ActionTypes.UPLOAD_PROGRESS;
  constructor(public payload: UpdateStr<FileUpload>) {}
}

export class UploadFailure implements Action {
  readonly type = ActionTypes.UPLOAD_FAILURE;
  constructor(public payload: UpdateStr<FileUpload>) {}
}

export class UploadCompleted implements Action {
  readonly type = ActionTypes.UPLOAD_COMPLETED;
  constructor(
    public payload: UpdateStr<FileUpload>,
    public isDocument: boolean
  ) {}
}

export class UploadFileDelete implements Action {
  readonly type = ActionTypes.UPLOAD_FILE_DELETE;
  constructor(public payload: { id: string }) {}
}

export class UploadNoop implements Action {
  readonly type = ActionTypes.UPLOAD_NOOP;
}

export type Actions =
  | UploadReset
  | UploadRequest
  | UploadStarted
  | UploadProgress
  | UploadFailure
  | UploadFileDelete
  | UploadCompleted
  | UploadAddFailed
  | UploadNoop;
