import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import {
  companiesFromAccountExistGuardActions,
  companyFromVacancyExistsGuardActions,
} from '@app/features/vacancy/store/actions';
import { navigateToCompanyFromVacancy } from '@app/features/vacancy/store/actions/list-vacancy.actions';
import { QueryOptions } from '@app/shared/models';
import { CompanyResource } from '@company/resources/company.resource';
import { LegalEntityService } from '@company/services/legal-entity.service';
import * as actions from '@company/store/actions/company.actions';
import { MessageError, isMessageError } from '@core/models';
import { LegalEntity, LegalEntityState } from '@mkp/account/data-access';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  loadCompaniesFromAccountFailure,
  loadCompaniesFromAccountSuccess,
  loadCompaniesFromVacancyFailure,
  loadCompaniesFromVacancySuccess,
} from '../actions/company-api.actions';
import { selectCompanyByVacancy } from '../selectors';

@Injectable()
export class CompanyProfileEffects {
  companyGetCompaniesProfileList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.CompanyGetProfileList),
        switchMap(({ query }) => {
          const queryOptions = query || { limit: 25 };
          return this.companyResource.getCompanies(queryOptions).pipe(
            map(({ companyProfiles, totalCount, filter, _links }) =>
              actions.CompanyGetProfileListSuccess({
                companyProfiles,
                totalCount,
                filter,
                _links,
              })
            ),
            catchError(
              (error: unknown) =>
                isCompanyProfileError(error) && of(actions.CompanyGetProfileListFailure({ error }))
            )
          );
        })
      ),
    { useEffectsErrorHandler: false }
  );

  loadCompaniesFromVacancy = createEffect(() =>
    this.actions$.pipe(
      ofType(companyFromVacancyExistsGuardActions.canActivate, navigateToCompanyFromVacancy),
      filter(({ vacancy }) => Boolean(vacancy)),
      concatLatestFrom(({ vacancy }) => this.store.select(selectCompanyByVacancy(vacancy))),
      filter(([, companyProfile]) => !companyProfile),
      switchMap(([{ vacancy }]) => {
        const vacancyId = vacancy.id;
        return this.companyResource.getCompanies(getQueryOptions(vacancy.accountId)).pipe(
          map(({ companyProfiles }) =>
            loadCompaniesFromVacancySuccess({ vacancyId, companyProfiles })
          ),
          catchError(
            (error: unknown) =>
              isCompanyProfileError(error) &&
              of(loadCompaniesFromVacancyFailure({ vacancyId, error }))
          )
        );
      })
    )
  );

  loadCompaniesFromAccountId = createEffect(() =>
    this.actions$.pipe(
      ofType(companiesFromAccountExistGuardActions.canActivate),
      switchMap(({ accountId }) => {
        return this.companyResource.getCompanies(getQueryOptions(accountId)).pipe(
          map(({ companyProfiles }) =>
            loadCompaniesFromAccountSuccess({ accountId, companyProfiles })
          ),
          catchError(
            (error: unknown) =>
              isCompanyProfileError(error) &&
              of(loadCompaniesFromAccountFailure({ accountId, error }))
          )
        );
      })
    )
  );

  readonly navigateToCompanyFromVacancy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToCompanyFromVacancy),
        switchMap(({ vacancy }) =>
          this.store.select(selectCompanyByVacancy(vacancy)).pipe(filter(Boolean))
        ),
        tap((company) => this.router.navigate([`/${AppConfig.routes.company}/edit/${company.id}`]))
      ),
    { dispatch: false }
  );

  readonly activateCompany$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CompanyActivate),
        switchMap(({ company }) =>
          this.legalEntityService
            .patchValue<LegalEntity>({
              id: company.legalEntityId,
              state: LegalEntityState.Active,
              _version: company.legalEntityVersion,
            })
            .pipe(
              map((legalEntity) =>
                actions.CompanyActivateSuccess({
                  companyId: company.id,
                  legalEntityVersion: legalEntity._version,
                })
              ),
              catchError(
                (error: unknown) =>
                  isCompanyProfileError(error) && of(actions.CompanyActivateFailure({ error }))
              )
            )
        )
      );
    },
    { useEffectsErrorHandler: false }
  );

  readonly showNotificationOnActivateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CompanyActivateSuccess),
        tap(() => this.notificationService.show('COMPANY_LIST.MESSAGE.ACTIVATION.SUCCESS'))
      );
    },
    { dispatch: false }
  );

  readonly showNotificationOnActivateFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CompanyActivateFailure),
        tap(() =>
          this.notificationService.show('COMPANY_LIST.MESSAGE.ACTIVATION.FAILURE', {
            state: ActionState.Error,
          })
        )
      );
    },
    { dispatch: false }
  );

  readonly deleteCompany$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.CompanyDelete),
        switchMap(({ company }) =>
          this.legalEntityService
            .patchValue({
              id: company.legalEntityId,
              state: LegalEntityState.Archived,
              _version: company.legalEntityVersion,
            })
            .pipe(
              map(() => actions.CompanyDeleteSuccess({ companyId: company.id })),
              catchError(
                (error: unknown) =>
                  isCompanyProfileError(error) && of(actions.CompanyDeleteFailure({ error }))
              )
            )
        )
      ),
    { useEffectsErrorHandler: false }
  );
  

  readonly showNotificationOnDeleteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CompanyDeleteSuccess),
        tap(() => this.notificationService.show('COMPANY_LIST.MESSAGE.DELETION.SUCCESS'))
      );
    },
    { dispatch: false }
  );

  readonly showNotificationOnDeleteFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CompanyDeleteFailure),
        tap(() =>
          this.notificationService.show('COMPANY_LIST.MESSAGE.DELETION.FAILURE', {
            state: ActionState.Error,
          })
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: SnackbarService,
    private readonly legalEntityService: LegalEntityService,
    private readonly companyResource: CompanyResource,
    private readonly store: Store<State>,
    private readonly router: Router
  ) {}
}

const isCompanyProfileError = (error: unknown): error is MessageError =>
  isMessageError(error, 'CompanyProfileEffects');
const getQueryOptions = (accountId: string): QueryOptions => ({
  filter: `legalEntity.accounts.id==${accountId}`,
});
