import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
  getPlatformIds,
  getProductOfferingBenefits,
  Product,
  ProductCode,
} from '@mkp/shared/util-product';
import { inject, Injectable } from '@angular/core';
import { ProductResource } from '@mkp/shared/data-access';
import { exhaustMap, filter, pipe, tap } from 'rxjs';
import {
  ExplainBenefitsComponent,
  ProductOfferingModalType,
} from '@mkp/product/feature-product-benefits';
import { ModalService } from '@app/services/modal.service';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectSelectedAccountId } from '@mkp/account/state';

export interface ExplainBenefitsWrapperState {
  product: Product | null;
  isReadMode: boolean;
}

const initialState: ExplainBenefitsWrapperState = {
  product: null,
  isReadMode: true,
};
@Injectable()
export class ExplainBenefitsWrapperStore extends ComponentStore<ExplainBenefitsWrapperState> {
  private readonly productResource = inject(ProductResource);
  private readonly modalService = inject(ModalService);
  private readonly store = inject(Store);
  private readonly selectedAccountId$ = this.store.select(selectSelectedAccountId);

  constructor() {
    super(initialState);
  }

  readonly product$ = this.select(({ product }) => product).pipe(filter(Boolean));
  readonly isReadMode$ = this.select(({ isReadMode }) => isReadMode);

  readonly setProduct = this.updater((state, product: Product) => ({
    ...state,
    product,
  }));

  readonly setIsReadMode = this.updater((state, isReadMode: boolean) => ({
    ...state,
    isReadMode,
  }));

  readonly fetchProduct = this.effect<ProductCode>(
    pipe(
      concatLatestFrom(() => this.selectedAccountId$),
      exhaustMap(([productCode, selectedAccountId]) =>
        this.productResource
          .get(productCode, selectedAccountId)
          .pipe(tapResponse(this.setProduct, (error) => console.error(error)))
      )
    )
  );

  readonly openExplainBenefitsModal = this.effect<() => void>(
    pipe(
      concatLatestFrom(() => [this.product$, this.isReadMode$]),
      tap(([callback, product, isReadMode]) =>
        this.explainBenefitsModal(product, isReadMode, callback)
      )
    )
  );

  private explainBenefitsModal(product: Product, isReadMode: boolean, callback: () => void) {
    this.modalService
      .open<ProductOfferingModalType, { result: boolean }>(ExplainBenefitsComponent, {
        panelClass: ['skp-dialog'],
        autoFocus: true,
        data: {
          product,
          isReadMode,
          // adapt data to be able to use existing benefits dialog
          benefits: getProductOfferingBenefits(product),
          platforms: getPlatformIds(product.features),
        },
      })
      .afterClosed()
      .pipe(filter(Boolean), tap(callback))
      .subscribe();
  }
}
