<div class="feedback-header-wrapper">
  <ui-asset *ngIf="icon" class="feedback-header-icon" [class]="feedbackTypeClass" [name]="icon" />
  <div
    class="feedback-header-title title-xs"
    [innerHTML]="title | translate: translationParams"
  ></div>

  <div class="feedback-action" *ngIf="showCloseBtn; else buttonText">
    <ui-asset name="close" (click)="handleAction.emit()" mkpDataTest="feedback-header-close-btn" />
  </div>
  <ng-template #buttonText>
    <ui-button
      *ngIf="buttonConfig"
      [state]="state"
      [config]="buttonConfig"
      (click)="handleAction.emit()"
      mkpDataTest="feedback-header-cta-btn"
    >
      {{ this.buttonText }}
    </ui-button>
  </ng-template>
</div>
