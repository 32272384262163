import { Document } from '@documents/models/document.model';

export const MAX_FILESIZE = 15728640; // 15Mbytes in bytes

export enum UploadStatus {
  Ready = 'Ready',
  Requested = 'Requested',
  Started = 'Started',
  Failed = 'Failed',
  Completed = 'Completed',
}

export enum FileType {
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  RTF = 'application/rtf',
  ODT = 'application/vnd.oasis.opendocument.text',
}

export const officeFileTypes = [FileType.DOC, FileType.DOCX, FileType.RTF, FileType.ODT];

export const imageFileTypes = [FileType.JPG, FileType.JPEG, FileType.GIF, FileType.PNG];

export function reverseFileType(type: string): FileType | null {
  switch (type) {
    case 'application/pdf':
      return FileType.PDF;
    case 'image/png':
      return FileType.PNG;
    case 'image/jpg':
      return FileType.JPG;
    case 'image/jpeg':
      return FileType.JPEG;
    case 'image/gif':
      return FileType.GIF;
    case 'application/msword':
      return FileType.DOC;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return FileType.DOCX;
    case 'application/rtf':
      return FileType.RTF;
    case 'application/vnd.oasis.opendocument.text':
      return FileType.ODT;
    default:
      return null;
  }
}

export enum ProgressMode {
  Determinate = 'determinate',
  Indeterminate = 'indeterminate',
}

export enum ProgressColor {
  Primary = 'primary',
  Accent = 'accent',
  Warn = 'warn',
}

export enum FileErrorType {
  Size = 'size',
  Type = 'type',
}

export class FileUpload {
  id: string | null = null;
  file: File | null = null;
  status?: UploadStatus = UploadStatus.Requested;
  percentage?: number | null = null;
  progressMode: ProgressMode = ProgressMode.Indeterminate;
  progressColor: ProgressColor = ProgressColor.Primary;
  message: string | null = null;
  document?: Document | null = null;
  removeFile?: boolean | null = null;

  constructor(
    id: string,
    file: File,
    status: UploadStatus,
    percentage: number,
    message: string | null = null
  ) {
    this.id = id;
    this.file = file;
    this.status = status;
    this.percentage = percentage;
    this.message = message;
  }
}
