import { Candidate } from '@candidates/models/candidate.model';
import { Document, DocumentType } from '@documents/models/document.model';
import { fromDocuments } from '@features/documents/store/reducers';
import { createSelector } from '@ngrx/store';

export const {
  selectLoading,
  selectLoaded,
  selectSelectedEntity,
  selectEntities,
  selectFeatureState,
  selectAll: selectDocuments,
} = fromDocuments.selectors;

export const selectCVDocument = createSelector(selectDocuments, (documents) =>
  documents.find((document) => document.classification === DocumentType.Cv)
);

export const selectDocumentsByCandidateId = (candidateId: Candidate['id']) =>
  createSelector(selectDocuments, (documents) =>
    documents.filter((document: Document) => document.candidateId === candidateId)
  );

export const selectPhotoByCandidateId = (candidateId: Candidate['id']) =>
  createSelector(selectDocumentsByCandidateId(candidateId), (documents) =>
    documents.find((document: Document) => document.classification === DocumentType.Photo)
  );
