import { LegalEntityState, isAccount } from '@mkp/account/data-access';
import { createSelector } from '@ngrx/store';
import { Vacancy } from '@vacancy/models/vacancy.model';
import { filter, map, pipe } from 'rxjs';
import { accountFeature } from './account.reducer';

const { selectEntities, selectSelectedAccountId, selectAll: selectAccounts } = accountFeature;

export { selectSelectedAccountId, selectAccounts };

export const selectAccountById = (accountId: string | undefined) =>
  createSelector(accountFeature.selectEntities, (accounts) =>
    accountId ? accounts[accountId] : undefined
  );

export const selectSelectedAccount = createSelector(
  selectEntities,
  selectSelectedAccountId,
  (entities, selectedAccountId) => (selectedAccountId ? entities[selectedAccountId] : undefined)
);

export const selectErecruiterPipe = pipe(
  map(selectSelectedAccount),
  filter(Boolean),
  map((account) => account.settings.ats.name === 'ERECRUITER')
);

export const selectIsInternalATSPipe = pipe(
  map(selectSelectedAccount),
  filter(Boolean),
  map((account) => account.settings.ats.name === 'INTERNAL')
);

export const selectLegalEntityNameFromVacancy = (vacancy: Vacancy) =>
  createSelector(selectLegalEntityFromVacancy(vacancy), (legalEntity) => legalEntity?.name);

export const selectAccountsByIds = (accountIds: string[]) =>
  createSelector(selectEntities, (accounts) =>
    accountIds.map((accountId) => accounts[accountId]).filter(Boolean)
  );

export const selectHasSelectedAccount = createSelector(
  selectSelectedAccount,
  (selectSelectedAccount) => !!selectSelectedAccount
);

export const selectIsLegalEntityBeingVerified = createSelector(
  selectSelectedAccount,
  // selectedAccount can be null for multi-user accounts. We return false in this case
  (account): boolean =>
    isAccount(account) &&
    [LegalEntityState.InVerification, LegalEntityState.OnHold].includes(account.legalEntity.state)
);

const selectAccountFromVacancy = ({ accountId }: Vacancy) => selectAccountById(accountId);
const selectLegalEntityFromVacancy = (vacancy: Vacancy) =>
  createSelector(selectAccountFromVacancy(vacancy), (account) => account?.legalEntity);
