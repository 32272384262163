import { ProductPlatformGroup } from '../models/product-offering-feature.model';
import { ProductCode } from '../models/product.model';

export interface ProductDescription {
  name: string;
  longname: string;
  shortname?: string;
  description: string;
  availablePromotions?: string;
}

export const PRODUCT_PLATFORM_GROUP_LOGOS: {
  [key in ProductPlatformGroup]: string;
} = {
  [ProductPlatformGroup.Jobs]: '/assets/logos/jobs-ch.svg',
  [ProductPlatformGroup.JobScout24]: '/assets/logos/jobscout24-logo.svg',
  [ProductPlatformGroup.JobUp]: '/assets/logos/jobup-logo.svg',
};

export const PRODUCT_PLATFORM_GROUP_TRANSLATIONS: {
  [key in ProductPlatformGroup]: string;
} = {
  [ProductPlatformGroup.Jobs]: 'PRODUCT_OFFERING.PLATFORM.JOBS',
  [ProductPlatformGroup.JobScout24]: 'PRODUCT_OFFERING.PLATFORM.JOB-SCOUT-24',
  [ProductPlatformGroup.JobUp]: 'PRODUCT_OFFERING.PLATFORM.JOBUP',
};

export const PRODUCT_TRANSLATIONS: {
  [key in ProductCode]: ProductDescription;
} = {
  JS24FREE: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JS24FREE',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JS24FREE',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JS24FREE',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JS24FREE',
  },
  JS24SOLO: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JS24SOLO',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JS24SOLO',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JS24SOLO',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JS24SOLO',
  },
  JS24PLUS: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JS24PLUS',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JS24PLUS',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JS24PLUS',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JS24PLUS',
  },
  JS24BASIC: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JS24BASIC',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JS24BASIC',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JS24BASIC',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JS24BASIC',
  },
  JS24ADVANCED: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JS24ADVANCED',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JS24ADVANCED',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JS24ADVANCED',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JS24ADVANCED',
  },
  JOBSSTARTER: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSSTARTER',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSSTARTER',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSSTARTER',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBSSTARTER',
  },
  JOBSLIMITED: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSLIMITED',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSLIMITED',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSLIMITED',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBSLIMITED',
  },
  JOBSFLEX: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSFLEX',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSFLEX',
    description: '',
  },
  JOBSOFFER22FREE: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSOFFER22FREE',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSOFFER22FREE',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSOFFER22FREE',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_JOBOFFER22FREE',
  },
  JOBSOFFER22XS: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSOFFER22XS',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSOFFER22XS',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSOFFER22XS',
    availablePromotions: 'PRODUCT_OPTIONS.AVAILABLE_JOBOFFER22XS',
  },
  JOBSOFFER22S: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSOFFER22S',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSOFFER22S',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSOFFER22S',
    availablePromotions: 'PRODUCT_OPTIONS.AVAILABLE_JOBSOFFER22S',
  },
  JOBSOFFER22M: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSOFFER22M',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSOFFER22M',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSOFFER22M',
    availablePromotions: 'PRODUCT_OPTIONS.AVAILABLE_JOBSOFFER22M',
  },
  JOBSOFFER22L: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSOFFER22L',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSOFFER22L',
    shortname: 'PRODUCT_OPTIONS.PRODUCT.SHORTNAME_JOBSOFFER22L',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSOFFER22L',
    availablePromotions: 'PRODUCT_OPTIONS.AVAILABLE_JOBSOFFER22L',
  },
  JOBSOFFER22XL: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSOFFER22XL',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSOFFER22XL',
    shortname: 'PRODUCT_OPTIONS.PRODUCT.SHORTNAME_JOBSOFFER22XL',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSOFFER22XL',
    availablePromotions: 'PRODUCT_OPTIONS.AVAILABLE_JOBSOFFER22XL',
  },
  JOBSSTANDARD: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBSSTANDARD',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBSSTANDARD',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBSSTANDARD',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBSSTANDARD',
  },
  JOBUP24FREE: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBUP24FREE',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBUP24FREE',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBUP24FREE',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBUP24FREE',
  },
  JOBUP24LITE: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBUP24LITE',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBUP24LITE',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBUP24LITE',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBUP24LITE',
  },
  JOBUP24BASIC: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBUP24BASIC',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBUP24BASIC',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBUP24BASIC',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBUP24BASIC',
  },
  JOBUP24ADVANCED: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBUP24ADVANCED',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBUP24ADVANCED',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBUP24ADVANCED',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBUP24ADVANCED',
  },
  JOBUP24ULTIMATE: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBUP24ULTIMATE',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBUP24ULTIMATE',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBUP24ULTIMATE',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBUP24ULTIMATE',
  },
  JOBUP24ULTIMATEPLUS: {
    name: 'PRODUCT_OPTIONS.PRODUCT.NAME_JOBUP24ULTIMATEPLUS',
    longname: 'PRODUCT_OPTIONS.PRODUCT.LONGNAME_JOBUP24ULTIMATEPLUS',
    description: 'PRODUCT_OPTIONS.PRODUCT.DESCRIPTION_JOBUP24ULTIMATEPLUS',
    availablePromotions: 'PRODUCT_OPTIONS.PRODUCT.AVAILABLE_PLATFORMS_JOBUP24ULTIMATEPLUS',
  },
};

export const BEST_VALUE_PRODUCTS: ProductCode[] = [
  ProductCode.JOBSOFFER22M,
  ProductCode.JS24ADVANCED,
  ProductCode.JOBUP24ADVANCED,
];

export const BEST_VALUE_PRODUCT_TRANSLATIONS: { [key in ProductPlatformGroup]: string } = {
  [ProductPlatformGroup.Jobs]: 'PRODUCT_OFFERING.JOBS.BEST_VALUE',
  [ProductPlatformGroup.JobScout24]: 'PRODUCT_OFFERING.JOBS24.BEST_VALUE',
  [ProductPlatformGroup.JobUp]: 'PRODUCT_OFFERING.JOBUP.BEST_VALUE',
};

export const LITE_PRODUCT_AVAILABLE_SUBCATEGORIES = [
  {
    name: 'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.TITLE.ADMIN_HR_CONSULTING_CEO',
    items: [
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.SECRETARY_RECEPTION',
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.MANAGEMENT_ASSISTANCE',
    ],
  },
  {
    name: 'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.TITLE.PURCHASING_LOGISTICS_TRADING',
    items: [
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.PURCHASING_PROCUREMENT',
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.LOGISTICS_SUPPLY_CHAIN',
    ],
  },
  {
    name: 'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.TITLE.MACHINE_PLANT_MANUFACTURING',
    items: [
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.MANUFACTURING_PRODUCTION_INDUSTRY',
    ],
  },
  {
    name: 'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.TITLE.VEHICLES_CRAFT_WAREHOUSE_TRANSPORT',
    items: [
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.FACILITY_MANAGEMENT_CLEANING',
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.STORAGE',
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.TRANSPORT_AIR_SEA_RAIL_ROAD',
    ],
  },
  {
    name: 'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.TITLE.CATERING_FOOD_TOURISM',
    items: [
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.COOKING_CUISINE',
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.SERVICE_BAR',
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.FRONTDESK_PORTER',
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.ROOMSERVICE_CLEANING_HOUSEKEEPING',
    ],
  },
  {
    name: 'SURVEILLANCE_POLICE_CUSTOMS_RESCUE',
    items: [
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.SURVEILLANCE_CONTROL_SECURITY',
      'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.ITEM.RESCUE_FIRE',
    ],
  },
];

export const DEPRECATED_PRODUCT_CODES = ['JOBSLIMITED'];
