import { gql } from '@apollo/client/core';

export const documentSchemas = {
  minimal: gql`
    fragment minimalDocumentFragment on Document {
      id
      fileName
      mimeType
    }
  `,
  all: gql`
    fragment documentFragment on Document {
      id
      classification
      fileName
      fileSize
      mimeType
      content
      visibleToCandidate
      transferType
      candidateId
      applicationId
      messageAttachmentId
      userId
      createdOn
    }
  `,
};

export const GET_DOCUMENTS_BY_CANDIDATES = gql`
  query documents($id: ID!) {
    documents(id: $id) {
      ...documentFragment
    }
  }
  ${documentSchemas.all}
`;
