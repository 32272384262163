import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseHttpResource, CoreListEnvelop, QueryParams } from '@mkp/shared/data-access';
import { map, Observable } from 'rxjs';
import { ApplicationStatus, ApplicationStatusDto } from './application-status.dto';
import { mapApplicationStatusDtoToModel } from './application-status.mapper';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusResource extends BaseHttpResource<
  ApplicationStatusDto,
  CoreListEnvelop<ApplicationStatusDto>,
  ApplicationStatus
> {
  constructor() {
    super('application-status');
  }

  fetchApplicationStatuses(): Observable<ApplicationStatus[]> {
    return this.http
      .get<
        CoreListEnvelop<ApplicationStatusDto>
      >(getApplicationStatusUrl(this.uri), { params: getApplicationStatusParams() })
      .pipe(
        map(({ _embedded: { results: applicationStatusDtos } }) =>
          applicationStatusDtos.map(mapApplicationStatusDtoToModel)
        )
      );
  }
}

const getApplicationStatusUrl = (uri: string): string => `${environment.api.internalATS}/${uri}`;
const getApplicationStatusParams = (): QueryParams => ({
  sort: `step=asc`,
  limit: 5, // temporary until we agree on limit number of fetching application statuses
});
