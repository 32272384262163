import { Injectable } from '@angular/core';
import { State } from '@app/store';
import { selectSelectedAccount } from '@mkp/account/state';
import { SentryService } from '@mkp/core/util-sentry';
import { LOCAL_STORAGE_SIGN_UP } from '@mkp/onboarding/feature-claim-company';
import { ClaimCompanyActions } from '@mkp/onboarding/feature-claim-company/actions';
import { Category, TealiumService } from '@mkp/tracking/feature-tealium';
import { TrackingService } from '@mkp/tracking/feature-tracking-old';
import { TrackingActions } from '@mkp/tracking/state/actions';
import { LOCAL_STORAGE_LOGIN } from '@mkp/user/feature-login';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { createVacancyWrapperActions, listVacancyActions } from '@vacancy/store/actions';
import { exhaustMap, filter, map, tap } from 'rxjs';
import { authActions } from '@mkp/auth/actions';

@Injectable()
export class TrackingEffects {
  readonly loadTealiumStartEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrackingActions.loadTealiumStart),
        exhaustMap(() =>
          this.tealiumService.isBooted$.pipe(
            map((isBooted) =>
              isBooted ? TrackingActions.loadTealiumSuccess() : TrackingActions.loadTealiumFailed()
            )
          )
        )
      ),
    { useEffectsErrorHandler: false }
  );

  readonly loadTealiumStartFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrackingActions.loadTealiumFailed),
        tap(() =>
          this.sentryService.captureException(
            new Error(`TealiumService Not Found - utag was not found (loadTealiumStartFailed$)`)
          )
        )
      ),
    { dispatch: false, useEffectsErrorHandler: false }
  );

  /**
   * @description detect browser navigation and emit a page_view event
   */
  readonly sendPageViewEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        concatLatestFrom(() => [
          this.store.select(selectLoggedInUser),
          this.store.select(selectSelectedAccount),
        ]),
        map(([, user, account]) => this.trackingService.pageVisited({ user, account })),
        exhaustMap(this.tealiumService.view)
      ),
    { dispatch: false }
  );

  /**
   * @description main effect to dispatch a Pure Frontend Event
   */
  readonly sendPureFrontendEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrackingActions.sendPureFrontendEvent),
        map(removeTypeProperty),
        map((category) => this.tealiumService.link(category))
      ),
    { dispatch: false }
  );

  // we send the login/signup event after loading initial account, because this call can fail
  // -> if the call fails: user is forced logged-out, so we shouldn't send login event
  //
  // Previously, both regular and BO user loaded initial account
  // -> this means that historically we have been sending login event for BO user
  // -> we could check with marketing if they still need this (probably not)
  //
  // Now BO user no longer load an initial account (because it's useless for them)
  // -> to avoid regressions I manually added the login/signup event for BO user
  // -> this is done by determining when we consider a BO user to be logged-in.
  // -> since they don't load initial account they are logged-in slightly before regular user
  // -> they are logged-in when they receive the membership count
  // -> cf code in hasInitialAccountOrIsBoUser
  readonly sendLoginEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        authActions.loadAccountsAfterLoginSuccess,
        authActions.loadActiveAccountMembershipsSuccess
      ),
      filter(hasInitialAccountOrIsBoUser),
      filter(() => !!localStorage.getItem(LOCAL_STORAGE_LOGIN)),
      tap(() => localStorage.removeItem(LOCAL_STORAGE_LOGIN)),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([, user, account]) => this.trackingService.loginData({ user, account })),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  readonly sendSignupEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        authActions.loadAccountsAfterLoginSuccess,
        authActions.loadActiveAccountMembershipsSuccess
      ),
      filter(hasInitialAccountOrIsBoUser),
      filter(() => !!localStorage.getItem(LOCAL_STORAGE_SIGN_UP)),
      tap(() => localStorage.removeItem(LOCAL_STORAGE_SIGN_UP)),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([, user, account]) => this.trackingService.signUpData({ user, account })),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description Vacancy Creation Event detection
   * This effect detects a Vacancy Creation and dispatches a Pure Frontend Event action.
   * TODO: Check the Vacancy Edit and Vacancy Duplicate. What marketing wants to do with those
   */
  // We should have a create add event not a publish add event. [TODO]
  // readonly jobAddPublishEvent$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CreateVacancySuccess),
  //     concatLatestFrom(() => this.store.select(selectMetadatasEntities)),
  //     map(([{ vacancy }, metadata]) =>
  //       trackingActions.sendPureFrontendEvent(
  //         this.trackingService.jobAdCreatedData(this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata))
  //       )
  //     )
  //   )
  // );

  /**
   * @description create user event
   * This effect detects when a user is created and dispatches the respective event.
   */
  readonly userRegistrationEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrackingActions.sendRegistrationEvent),
      map((user) => this.trackingService.userRegistrationData({ user })),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description user lands on the CMS page
   */
  readonly sendCmsEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrackingActions.sendCmsEvent),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([{ payload: eventCategory, cmsTracking: contentCategory }, user, account]) =>
        this.trackingService.cmsEventData({
          eventCategory,
          contentCategory,
          user,
          account,
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description job ad creation start (SE-647)
   * This effect detects when a user starts a new job-ad, and dispatches that event
   */
  readonly jobAdCreateStartEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrackingActions.sendJobAdCreateStartEvent),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([, user, account]) => this.trackingService.jobCreateStartData({ user, account })),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description the active step in the stepper is changed during job ad creation
   * This effect detects when the user clicks on the stepper or when the step changes automatically
   */
  readonly activeStepChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createVacancyWrapperActions.activeStepChanged),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([{ stepIndex, nbSuggestions, vacancyTemporaryId, vacancyId }, user, account]) =>
        this.trackingService.jobAdCreateStepData({
          stepIndex,
          nbSuggestions,
          vacancyTemporaryId,
          vacancyId,
          user,
          account,
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description go back button was clicked in the job-ad creation funnel
   * This effect detects when a user clicks on the go back button, while at any step in the vacancy creation
   */
  readonly goBackButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createVacancyWrapperActions.goBackButtonClicked),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([{ stepIndex: funnelStep }, user, account]) =>
        this.trackingService.jobAdBackButtonClickData({ user, account, funnelStep })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description "suggest a job ad" button was clicked in the job-ad creation funnel
   * This effect detects when a user clicks on the "suggest a job ad" button, in the first step of the vacancy creation process
   */
  readonly suggestButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createVacancyWrapperActions.suggestButtonClicked),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([{ vacancyId: job_id, vacancyTemporaryId: job_tmp_id }, user, account]) =>
        this.trackingService.jobAdSuggestButtonClickData({ job_id, job_tmp_id, user, account })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description "stop generate" button was clicked in the job-ad creation funnel
   * This effect detects when a user clicks on the "stop generate" button, in the first step of the vacancy creation process
   */
  readonly cancelSuggestButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createVacancyWrapperActions.cancelSuggestButtonClicked),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([{ vacancyId: job_id, vacancyTemporaryId: job_tmp_id }, user, account]) =>
        this.trackingService.jobAdCancelSuggestButtonClickData({
          job_id,
          job_tmp_id,
          user,
          account,
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description the active step in the stepper is changed during job ad creation
   * This effect detects when the user clicks on the stepper or when the step changes automatically
   */
  readonly deleteDraftVacancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listVacancyActions.deleteDraftVacancy),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([{ id }, user, account]) => {
        return this.trackingService.deleteDraftVacancyData({
          id,
          user,
          account,
        });
      }),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description intercom chat support
   * This effect detects when a user clicks on a button in order to chat with customer support (company claim)
   */
  readonly contactCustomerSupport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimCompanyActions.contactCustomerSupport),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([, user, account]) =>
        this.trackingService.contactCustomerSupportData({ user, account })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description logo guidelines
   * This effect detects when a user clicks on a button in order to open logo guidelines
   */
  readonly openLogoGuidelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrackingActions.sendOpenLogoGuidelinesEvent),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([, user, account]) => this.trackingService.openLogoGuidelines({ user, account })),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  constructor(
    private readonly store: Store<State>,
    private readonly actions$: Actions,
    private readonly sentryService: SentryService,
    private readonly tealiumService: TealiumService,
    private readonly trackingService: TrackingService
  ) {}
}

const removeTypeProperty = (
  categoryFull: ReturnType<typeof TrackingActions.sendPureFrontendEvent>
): Category => {
  const { type, ...category } = { ...categoryFull };
  return category;
};

type LoadInitialAccountAction = ReturnType<typeof authActions.loadAccountsAfterLoginSuccess>;
type LoadAccountMembershipCountSuccessAction = ReturnType<
  typeof authActions.loadActiveAccountMembershipsSuccess
>;
type LoginAction = LoadInitialAccountAction | LoadAccountMembershipCountSuccessAction;
const isLoadInitialAccountSuccessAction = (
  loginAction: LoginAction
): loginAction is LoadInitialAccountAction =>
  (loginAction as LoadInitialAccountAction)?.accounts?.length > 0;

const hasInitialAccountOrIsBoUser = (action: LoginAction): boolean =>
  isLoadInitialAccountSuccessAction(action) || action.accountMemberships.length > 0;
