import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataService, EntityDefinitionService } from '@ngrx/data';
import { ConversationsEffects } from '@conversation/store/effects/conversations.effects';
import { ConversationsDataService } from '@conversation/services';
import { entityMetadata } from '@conversation/store/entity-metadata';

@NgModule({
  imports: [EffectsModule.forFeature([ConversationsEffects])],
})
export class ConversationStateModule {
  constructor(
    eds: EntityDefinitionService,
    entityDataService: EntityDataService,
    conversationsDataService: ConversationsDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerService('Conversation', conversationsDataService);
  }
}
