import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import { getMessageError } from '@app/core/models';
import { BoDashboardService } from '@app/core/services/bo-dashboard.service';
import { vacancyApiActions } from '@app/features/vacancy/store/actions/vacancy-api.actions';
import { accountTypeaheadActions } from '@app/store/actions';
import { AccountResource, addLegalEntityToAccountDto } from '@mkp/account/data-access';
import { accountApiActions } from '@mkp/account/data-access/actions';
import { claimManagementPageActions } from '@mkp/legal-entity/feature-claim-management/actions';
import { getUrlWithoutParameters } from '@mkp/shared/util-format';
import { BoDashboardActions } from '@mkp/user/feature-bo-dashboard/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  OperatorFunction,
  catchError,
  filter,
  map,
  mergeMap,
  of,
  pipe,
  switchMap,
  tap,
} from 'rxjs';
import { LegalEntityService } from '@company/services/legal-entity.service';
import { AccountDto } from '../../../../data-access/src/lib/account/account.dto';
import { addLegalEntityToAccountsDto } from '@mkp/account/data-access';
import { userSelectors } from '@user/store/selectors';

// on the vacancy-list, BO users have one more column than regular user: the name of the legal-entity
// so we need to download the accounts linked to these vacancy only for BO user
export const fetchAccountsFromVacancies = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityService)
  ) =>
    actions$.pipe(
      ofType(vacancyApiActions.listVacanciesSuccess, vacancyApiActions.loadMoreVacanciesSuccess),
      concatLatestFrom(() => store.select(userSelectors.selectIsBoUser)),
      filter(([{ vacancies }, isBoUser]) => !!vacancies.length && isBoUser),
      map(([{ vacancies }]) => vacancies.map(({ accountId }) => accountId)),
      fetchAccountsFromAccountIds(accountResource, legalEntityResource,'fetchAccountsFromVacancies')
    ),
  { functional: true }
);

// BO users have access to the claim-management page, that lists the legal-entities
// on this page they have an "Enter company" button, when they click it we need to fetch the account and select it
export const fetchAccountFromSelectedLegalEntity = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityService)
  ) =>
    actions$.pipe(
      ofType(claimManagementPageActions.selectLegalEntity),
      switchMap(({ legalEntityExternalId }) =>
        accountResource.fetchByLegalEntityExternalId(legalEntityExternalId).pipe(
          filter(Boolean),
          addLegalEntityToAccountDto(legalEntityResource),
          map((account) =>
            accountApiActions.loadAccountByLegalEntityExternalIdSuccess({
              account,
              selectedAccountId: account.id,
            })
          ),
          catchError((error: unknown) =>
            of(
              accountApiActions.loadAccountByLegalEntityExternalIdFailure({
                error: getMessageError(error, 'fetchAccountFromSelectedLegalEntity'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

// when selecting a company from the claim-management page: we should add it to the bo-dashboard state
// IMPR: bo-dashboard should be a local-store and listen to this action
export const addAccountToBoDashboardWhenSelectedOnClaimManagement = createEffect(
  (actions$ = inject(Actions), boDashboardService = inject(BoDashboardService)) =>
    actions$.pipe(
      ofType(claimManagementPageActions.selectLegalEntity),
      ofType(accountApiActions.loadAccountByLegalEntityExternalIdSuccess),
      tap(({ account }) => boDashboardService.addAccount(account))
    ),
  { functional: true, dispatch: false }
);

// we have a "last selected companies" feature in two places for BO user:
// - on the BO dashboard (enter)
// - in the account selector typeahead (initDropdown)
export const loadLastSelectedAccountsForBoUser = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityService)
  ) =>
    actions$.pipe(
      ofType(BoDashboardActions.enter, BoDashboardActions.initDropdown),
      filter(({ accountIds }) => accountIds.length > 0),
      map(({ accountIds }) => accountIds),
      fetchAccountsFromAccountIds(accountResource, legalEntityResource, 'loadLastSelectedAccountsForBoUser')
    ),
  { functional: true }
);

// we have a "last edited job ads" feature for BO user in the dashboard
// we need to display the name of the legal-entity so we're fetching the account
export const loadAccountsFromLastEditedVacancies = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityService)
  ) =>
    actions$.pipe(
      ofType(vacancyApiActions.loadVacanciesByIdsSuccess),
      map(({ vacancies }) => vacancies.map(({ accountId }) => accountId)),
      fetchAccountsFromAccountIds(accountResource, legalEntityResource, 'loadAccountsFromLastEditedVacancies')
    ),
  { functional: true }
);

// when a BO user is on the dashboard and selects an account we need to redirect to vacancy-list
export const redirectOnSelectingAccount = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(accountTypeaheadActions.selectAccount),
      filter(() => isDashboardRoute(router.url)),
      tap(() => router.navigateByUrl(`/${AppConfig.routes.vacancy}`))
    ),
  { functional: true, dispatch: false }
);

const fetchAccountsFromAccountIds = (
  accountResource: AccountResource,
  legalEntityResource: LegalEntityService,
  errorNamespace: string
): OperatorFunction<string[], Action> =>
  pipe(
    map((accountIds) => removeDuplicates(accountIds as string[])),
    mergeMap((accountIds) =>
      accountResource.fetchByIds(accountIds).pipe(
        addLegalEntityToAccountsDto(legalEntityResource),
        map((accounts) => accountApiActions.loadAccountsSuccess({ accounts })),
        catchError((error: unknown) =>
          of(
            accountApiActions.loadAccountsFailure({
              error: getMessageError(error, errorNamespace),
            })
          )
        )
      )
    )
  );

const removeDuplicates = (accountIds: string[]): string[] => Array.from(new Set(accountIds));

const isDashboardRoute = (url: string) =>
  getUrlWithoutParameters(url) === `/${AppConfig.routes.dashboard}`;

const getLegalEntityFilter = (accounts: AccountDto[]): string =>
  accounts.map(account => `id==${account.legalEntityId}`).join(',');
