import { gql } from '@apollo/client/core';
import { documentSchemas } from '@documents/schemas/document.schema';

export const messageFragment = gql`
  fragment messageFragment on Message {
    id
    applicationId
    body
    candidateId
    candidateName
    isRead
    isReceived
    jobTitle
    readAt
    sentAt
    subject
    vacancyId
  }
`;

export const GET_MESSAGES_BY_APPLICATION = gql`
  query messagesByApplication($applicationId: String!) {
    messagesByApplication(applicationId: $applicationId) {
      ...messageFragment
      documents {
        ...minimalDocumentFragment
      }
    }
  }
  ${messageFragment}
  ${documentSchemas.minimal}
`;

export const PREVIEW_MESSAGE = gql`
  query previewMessage($message: SendMessageInput!) {
    previewMessage(message: $message) {
      ...messageFragment
    }
  }
  ${messageFragment}
`;

export const DELETE_MESSAGE = gql`
  mutation deleteMessage($id: String!) {
    deleteMessage(id: $id)
  }
`;

export const READ_MULTI_MESSAGES = gql`
  mutation readMultiMessages($ids: [String!]) {
    readMultiMessages(ids: $ids)
  }
`;

export const UNREAD_MULTI_MESSAGES = gql`
  mutation unreadMultiMessages($ids: [String!]) {
    unreadMultiMessages(ids: $ids)
  }
`;

export const DELETE_MULTI_MESSAGES = gql`
  mutation deleteMultiMessages($ids: [String!]) {
    deleteMultiMessages(ids: $ids)
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($message: SendMessageInput!) {
    sendMessage(message: $message) {
      ...messageFragment
      documents {
        ...minimalDocumentFragment
      }
    }
  }
  ${messageFragment}
  ${documentSchemas.minimal}
`;
