import { EntityMetadataMap } from '@ngrx/data';

function sortDateTime(a: { lastMessageTime: string }, b: { lastMessageTime: string }) {
  // If strings, compare as strings
  if (typeof a.lastMessageTime === 'string') {
    return b.lastMessageTime.localeCompare(a.lastMessageTime);
  }
}

export const entityMetadata: EntityMetadataMap = {
  Conversation: {
    sortComparer: sortDateTime,
    // The defaults are the safe ones: optimistic for delete and pessimistic for add and update.
    // see https://ngrx.io/guide/data/entity-metadata#entitydispatcheroptions
    entityDispatcherOptions: {
      optimisticDelete: false,
    },
    // this fixes error when calling removeManyFromCache
    // it appears somehow ngrxData violates strictStateImmutability runtimeCheck
    // this prevents us from setting optimistic transactions
    noChangeTracking: true,
  },
};
