import { EntityOp } from '@ngrx/data';

export const ENTITY_OPERATION_ERRORS = [
  EntityOp.QUERY_BY_KEY_ERROR,
  EntityOp.QUERY_LOAD_ERROR,
  EntityOp.QUERY_ALL_ERROR,
  EntityOp.QUERY_MANY_ERROR,
  EntityOp.SAVE_ADD_MANY_ERROR,
  EntityOp.SAVE_ADD_ONE_ERROR,
  EntityOp.SAVE_DELETE_MANY_ERROR,
  EntityOp.SAVE_DELETE_ONE_ERROR,
  EntityOp.SAVE_UPSERT_ONE_ERROR,
  EntityOp.SAVE_UPSERT_MANY_ERROR,
  EntityOp.SAVE_UPDATE_ONE_ERROR,
  EntityOp.SAVE_UPDATE_MANY_ERROR,
];
