import { AppConfig } from '@app/config/app.config';
import { selectUserDisplayType } from '@app/features/user/store/selectors/user.selectors';
import { selectRouterState } from '@app/store/selectors/router.selectors';
import { selectRelevantProductCode } from '@mkp/publication/feature-credit-status/state';
import { getUrlWithoutParameters } from '@mkp/shared/util-format';
import {
  JOBUP_PRODUCT_CODES,
  JS24_PRODUCT_CODES,
  PRODUCT_OFFERING_GROUP_MAP,
  Product,
  ProductCode,
  ProductPlatformGroup,
  getOriginalPrice,
  getPaidProductsOrderedByPrice,
  getSwitchedPlatformFrom,
  isFreeTrialProduct,
  isPaid,
  removeUnavailableJS24Product,
  sortProducts,
} from '@mkp/shared/util-product';
import { createFetchStateSelectors } from '@mkp/shared/util-state';
import { UserDisplayType } from '@mkp/user/data-access';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProductOptions from '@product-options/store/reducers/product-options.reducer';

const state = createFeatureSelector<fromProductOptions.State>(fromProductOptions.featureKey);

export const { selectLoaded: selectProductOptionsLoaded, selectLoading } =
  createFetchStateSelectors(state);

export const selectProducts = createSelector(state, (state) => state?.products);

export const selectProductsDiscount = createSelector(state, (state) => state?.productsDiscount);

export const selectActiveTab = createSelector(state, (state) => state.activeTab);

export const selectPaidJobsProductsOrderedByPrice = createSelector(
  selectProducts,
  getPaidProductsOrderedByPrice(ProductPlatformGroup.Jobs)
);

export const selectPaidJs24ProductsOrderedByPrice = createSelector(
  selectProducts,
  getPaidProductsOrderedByPrice(ProductPlatformGroup.JobScout24)
);

export const selectPaidJobUpProductsOrderedByPrice = createSelector(
  selectProducts,
  getPaidProductsOrderedByPrice(ProductPlatformGroup.JobUp)
);

export const selectHasFreeTrialProduct = createSelector(selectProducts, (products) =>
  Object.values(products ?? {}).some(isFreeTrialProduct)
);

export const selectSelectedPlatformGroup = createSelector(
  state,
  (state) => state.selectedPlatformGroup
);

export const selectRemovedCmsProductCodes = createSelector(
  state,
  (state) => state.removedCmsProductCodes
);

export const selectActivePlatformGroup = (vacancyId: string) =>
  createSelector(
    selectRelevantProductCode(vacancyId),
    selectSelectedPlatformGroup,
    selectUserDisplayType,
    (
      productCode,
      previouslySelectedPlatformGroup,
      userDisplayType // look for a previously selected platformGroup (in the same session)
    ) =>
      // otherwise, if there is a productCode: use the same platformGroup as the productCode
      // otherwise look for a UserDisplayType from the API
      previouslySelectedPlatformGroup ??
      (productCode
        ? getActivePlatformGroupFromProductCode(productCode)
        : getActivePlatformGroupFromUserDisplayType(userDisplayType))
  );

export const selectUpgrade = createSelector(state, (state) => state.upgrade);

export const selectFilteredProducts = (vacancyId: string, isForActivePlatformGroup = true) =>
  createSelector(
    selectProducts,
    selectActivePlatformGroup(vacancyId),
    (products, activePlatformGroup) => {
      if (!products || !activePlatformGroup) {
        return [];
      }

      const platformGroup = isForActivePlatformGroup
        ? activePlatformGroup
        : getSwitchedPlatformFrom(activePlatformGroup);
      return products?.filter(({ code }) =>
        PRODUCT_OFFERING_GROUP_MAP[platformGroup]?.includes(code)
      );
    }
  );

export const selectDisplayedProducts = (vacancyId: string, isForActivePlatformGroup = true) =>
  createSelector(
    selectProducts,
    selectFilteredProducts(vacancyId, isForActivePlatformGroup),
    selectUpgrade,
    selectProductsDiscount,
    (availableProducts, products, upgrade, productsDiscount) => {
      if (upgrade?.canUpgrade && availableProducts?.length) {
        const upgradeProductPrice = getOriginalPrice(
          availableProducts?.find(({ code }) => code === upgrade.productCode)
        );

        if (upgradeProductPrice >= 0) {
          return products.filter((product) => getOriginalPrice(product) > upgradeProductPrice);
        }
      }

      // Discount Price for CMS
      if (productsDiscount && products) {
        products = updatePrices(products, productsDiscount);
      }

      return products.sort(sortProducts);
    }
  );

const selectUrlWithoutParameters = createSelector(selectRouterState, (state) =>
  getUrlWithoutParameters(state?.state?.url)
);

export const selectPaidDisplayedProducts = (vacancyId: string, isForActivePlatformGroup = true) =>
  createSelector(selectDisplayedProducts(vacancyId, isForActivePlatformGroup), (products) =>
    // remove deprecated products
    {
      products = products ? removeUnavailableJS24Product(products) : [];
      return products.filter((product) => isPaid(product)).reverse();
    }
  );

export const selectFreeDisplayedProduct = (vacancyId: string, isForActivePlatformGroup = true) =>
  createSelector(selectDisplayedProducts(vacancyId, isForActivePlatformGroup), (products) =>
    products ? products.find((product) => !isPaid(product)) : null
  );

export const selectIsLoginPage = createSelector(selectUrlWithoutParameters, (url) =>
  url.includes(`/${AppConfig.routes.login}`)
);

export const selectIsLandingPage = createSelector(
  selectUrlWithoutParameters,
  (url) => url === `/${AppConfig.routes.home}`
);

export const selectIsCmsPage = createSelector(selectUrlWithoutParameters, (url) =>
  url.includes(`/${AppConfig.routes.cms}`)
);

export const selectIsSupportCenterPage = createSelector(selectUrlWithoutParameters, (url) =>
  url.includes(`/${AppConfig.routes.supportCenter}`)
);

const getActivePlatformGroupFromProductCode = (productCode: ProductCode): ProductPlatformGroup => {
  return isJS24(productCode)
    ? ProductPlatformGroup.JobScout24
    : isJobUp(productCode)
      ? ProductPlatformGroup.JobUp
      : ProductPlatformGroup.Jobs;
};

const isJS24 = (code: ProductCode): boolean => JS24_PRODUCT_CODES.includes(code);
const isJobUp = (code: ProductCode): boolean => JOBUP_PRODUCT_CODES.includes(code);

const getActivePlatformGroupFromUserDisplayType = (userDisplayType?: UserDisplayType) => {
  switch (userDisplayType) {
    case UserDisplayType.CustomerJobScout24:
      return ProductPlatformGroup.JobScout24;
    case UserDisplayType.CustomerJobUp:
      return ProductPlatformGroup.JobUp;
    case UserDisplayType.CustomerJobcloud:
    case UserDisplayType.CustomerJobs:
    default:
      return ProductPlatformGroup.Jobs;
  }
};

const updatePrices = (array: Product[], prices: Record<string, number>) => {
  return array.map((item) => {
    if (prices[item.code] !== undefined) {
      return {
        ...item,
        cmsDiscountPrice: {
          ...item.price,
          current: prices[item.code],
        },
      };
    }
    return item;
  });
};
