import { Injectable } from '@angular/core';
import { isLanguageCode } from '@mkp/shared/data-access';
import { UserDto, UserPatch } from '@mkp/user/data-access';
import { isUserDisplayType, isUserTitle } from '@user/shared/enums';
import { UpdateUser, User, defaultUser } from '@user/shared/models';

@Injectable({ providedIn: 'root' })
export class UserMapper {
  fromJson(userDto: UserDto): User {
    const displayType = isUserDisplayType(userDto.settings?.displayType)
      ? userDto.settings.displayType
      : defaultUser.settings.displayType;
    const language = isLanguageCode(userDto.settings?.language)
      ? userDto.settings.language
      : defaultUser.language;
    const title = isUserTitle(userDto.title) ? userDto.title : defaultUser.title;
    const marketingConsentAcceptedAt = userDto.settings?.marketingConsentAcceptedAt
      ? new Date(userDto.settings.marketingConsentAcceptedAt)
      : null;
    const publicationAiUsageConsent = userDto.settings?.publicationAiUsageConsent ?? null;
    const {
      id,
      email,
      firstName,
      lastName,
      emailVerified,
      profilePictureMediaApiId,
      phoneNumber,
      _version,
    } = userDto;
    return {
      id,
      email,
      firstName,
      lastName,
      title,
      emailVerified,
      profilePictureMediaApiId,
      phoneNumber,
      settings: {
        displayType,
        marketingConsentAcceptedAt,
        publicationAiUsageConsent,
      },
      language,
      _version,
    };
  }

  // We use PatchDto because create is never called on the core BE for users
  toJson(user: UpdateUser): UserPatch {
    // remove id for the PATCH payload
    const { id, language, phoneNumber, settings, ...userProperties } = user;

    // the only way to assert the type of a destructured object is to use a different constant
    const userDto: UserPatch = userProperties;
    const settingsDto: UserPatch['settings'] = {};

    // empty string is forbidden by BE, they want to receive "null" instead
    if (phoneNumber) {
      userDto.phoneNumber = convertToNull(user.phoneNumber);
    }

    // move language to a "settings" property
    if (language) {
      settingsDto.language = language;
    }

    // map the marketing consent flag
    if (settings?.marketingConsent !== undefined) {
      settingsDto.marketingConsentAcceptedAt = settings.marketingConsent ? 'now' : null;
    }

    if (settings?.publicationAiUsageConsent !== undefined) {
      settingsDto.publicationAiUsageConsent = settings.publicationAiUsageConsent;
    }

    // add the setting object to the DTO if it has any property
    if (Object.keys(settingsDto).length) {
      userDto.settings = { ...settingsDto };
    }

    return userDto;
  }
}

export const convertToNull = (text?: string | null): string | null => {
  return !text || text.trim() === '' ? null : text;
};
