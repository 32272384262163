import { Gdpr } from '@mkp/gdpr/state';
import { ErrorState } from '@mkp/shared/util-state';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const gdprTealiumActions = createActionGroup({
  source: 'Gdpr/Tealium',
  events: {
    'Load Gdpr From Tealium Success': props<{ gdpr: Gdpr }>(),
    'Load Gdpr From Tealium Failure': props<ErrorState<Record<string, unknown>>>(),
    'Set Gdpr To Tealium Success': emptyProps(),
    'Set Gdpr To Tealium Failure': props<ErrorState<Record<string, unknown>>>(),
    'Force Show Intercom Launcher': props<{ shouldForceShowIntercomLauncher: boolean }>(),
  },
});
