import { LoadingState } from '@mkp/shared/util-state';
import { createSelector } from '@ngrx/store';
import { gdprFeature } from './gdpr.reducer';

export const selectGdprIsLoaded = createSelector(
  gdprFeature.selectFetchState,
  (fetchState) => fetchState === LoadingState.LOADED
);
export const { selectIntercomLauncherForciblyShown, selectFunctional: selectGdprFunctional } =
  gdprFeature;
export const selectGdpr = createSelector(
  gdprFeature.selectFunctional,
  gdprFeature.selectMarketing,
  (functional, marketing) => ({ functional, marketing })
);
export const selectGdprIsDefined = createSelector(
  selectGdpr,
  ({ functional, marketing }) => functional != null && marketing != null
);
