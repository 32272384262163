import { Document } from '@app/features/documents/models';
import { DocumentContent } from '@documents/models/document-content.model';

export interface Basename {
  basename: string;
  suffix: string;
}

export const getBasename = (fileName: string): Basename => {
  const dotPosition = (fileName.lastIndexOf('.') - 1) >>> 0;
  return {
    basename: fileName.slice(0, dotPosition + 1),
    suffix: fileName.slice(dotPosition + 1),
  } as Basename;
};

/**
 * convert a base64 into a blob,
 * that's taken from internet, there's room for improvment.
 * @link https://stackoverflow.com/a/16245768
 * @param b64Data
 * @param contentType
 * @param sliceSize
 */
export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const downloadDocument = ({ content, mimeType, fileName }: DocumentContent): void => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(b64toBlob(content, mimeType));
  a.download = fileName;
  a.click();
};

// sort by classification first, and within the same classification sort by name
export const sortByClassificationAndName = (a: Document, b: Document) =>
  sortByClassification(a, b) || sortByName(a, b);

export const sortByClassification = (a: Document, b: Document) =>
  a.classification - b.classification;

export const sortByName = ({ fileName: a }: Document, { fileName: b }: Document) =>
  a.localeCompare(b);
