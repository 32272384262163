interface ApplicationStatusBase {
  id: string;
  accountId: string;
  name: {
    de: string;
    en: string;
    fr: string;
  };
  createdBy: string;
  updatedBy: string | null;
}

export interface ApplicationStatus extends ApplicationStatusBase {
  step: ApplicationStep;
  createdAt: Date;
  updatedAt: Date | null;
}

export interface ApplicationStatusDto extends ApplicationStatusBase {
  step: number;
  name: {
    de: string;
    en: string;
    fr: string;
  };
  createdAt: string;
  updatedAt: string | null;
}

export enum ApplicationStep {
  New = 1,
  Suitable,
  InConversation,
  Hired,
  Unsuitable,
}

export interface HeaderActionConfig {
  action: ApplicationStep | 'delete';
  isFromDropdownMenu: boolean;
}

export interface StatusChangeConfig {
  isStatusChanging: boolean;
  isFromDropdownMenu: boolean;
  newStep: ApplicationStep;
}

export interface ChangeStatusPayload {
  applicationId: string;
  newStep: ApplicationStep;
  isFromDropdownMenu: boolean;
  _version: string;
}

export const isApplicationStep = (value: number): value is ApplicationStep => {
  return Object.values(ApplicationStep).filter(Number).includes(value);
};
