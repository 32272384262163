import { AppConfig } from '@config/app.config';

export enum NavigationBlock {
  top,
  down,
}

export interface NavigationLink {
  title: string;
  icon?: string;
  route?: string | string[];
  strict?: boolean;
  block?: NavigationBlock;
  disabled?: boolean;
  dataTest: string;
  badgeKey?: string;
}

export const Navigation = {
  dashboard: {
    title: 'NAVIGATION.DASHBOARD',
    dataTest: 'dashboard',
    icon: 'home-full',
    route: AppConfig.routes.dashboard,
    strict: true,
  },
  posting: {
    title: 'NAVIGATION.VACANCY',
    dataTest: 'posting',
    icon: 'work_outline',
    route: `${AppConfig.routes.vacancy}`,
  },
  conversations: {
    title: 'NAVIGATION.MESSAGES',
    dataTest: 'conversations',
    icon: 'mail_outline',
    route: AppConfig.routes.conversations,
    badgeKey: 'conversations',
  },
  companies: {
    title: 'NAVIGATION.COMPANIES',
    dataTest: 'companies',
    icon: 'home_work',
    route: AppConfig.routes.company,
  },
  claimManagement: {
    title: 'NAVIGATION.CLAIM_MANAGEMENT',
    dataTest: 'claimManagement',
    icon: 'security',
    route: AppConfig.routes.claimManagement,
  },
  invoices: {
    title: 'NAVIGATION.INVOICES',
    dataTest: 'invoices',
    icon: 'receipt_long-24px',
    route: AppConfig.routes.invoices,
    disabled: true,
  },
  accounts: {
    title: 'NAVIGATION.USER_ACCOUNTS',
    dataTest: 'accounts',
    icon: 'account',
    route: AppConfig.routes.users,
  },
  publishVacancy: {
    title: 'NAVIGATION.CREATEJOB',
    dataTest: 'publishVacancy',
    icon: 'createJobPosting',
    route: [AppConfig.routes.vacancy, 'publish', 'create'],
  },
  profileInfo: {
    title: 'profileInfo',
    dataTest: 'profileInfo',
    block: NavigationBlock.down,
  },
  account: {
    title: 'NAVIGATION.ACCOUNT',
    dataTest: 'account',
    icon: 'person',
    route: AppConfig.routes.account,
    block: NavigationBlock.down,
  },
  logout: {
    title: 'NAVIGATION.LOGOUT',
    dataTest: 'logout',
    icon: 'logout',
    route: AppConfig.routes.logout,
    block: NavigationBlock.down,
  },
  language: {
    title: 'languagesList',
    dataTest: 'language',
    block: NavigationBlock.down,
  },
  supportCenter: {
    title: 'NAVIGATION.SUPPORT_CENTER',
    dataTest: 'supportCenter',
    icon: 'support-center',
  },
} satisfies Record<string, NavigationLink>;

export const TopHeaderMenuProfileNavigation = {
  account: Navigation.account,
  supportCenter: Navigation.supportCenter,
  logout: Navigation.logout,
} satisfies Record<string, NavigationLink>;

export const RegularUserNavigation = {
  dashboard: Navigation.dashboard,
  posting: Navigation.posting,
  conversations: Navigation.conversations,
  companies: Navigation.companies,
  invoices: Navigation.invoices,
} satisfies Record<string, NavigationLink>;
export const BoUserNavigation = {
  dashboard: Navigation.dashboard,
  posting: Navigation.posting,
  companies: Navigation.companies,
  invoices: Navigation.invoices,
  accounts: Navigation.accounts,
  claimManagement: Navigation.claimManagement,
} satisfies Record<string, NavigationLink>;
export const MultiUserNavigation = {
  dashboard: Navigation.dashboard,
  posting: Navigation.posting,
  companies: Navigation.companies,
  invoices: Navigation.invoices,
  accounts: Navigation.accounts,
} satisfies Record<string, NavigationLink>;
