import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Account } from '@mkp/account/data-access';
import { AccountMembership } from '@mkp/account-membership/data-access';
import { CompanyProfile } from '@company/models';

export const authActions = createActionGroup({
  source: 'Auth',
  events: {
    'App Bootstrapped': emptyProps(),
    'App Bootstrap Failed': props<{ error: string }>(),
    'User Identity Not Verified': emptyProps(),
    'User Is Verified': props<{ userId: string }>(),
    'Load Active Account Memberships Success': props<{ accountMemberships: AccountMembership[] }>(),
    'Load Active Account Memberships Failed': props<{ error: { message: string } }>(),
    'Load Accounts After Login Success': props<{ accounts: Account[] }>(),
    'Load Accounts After Login Failed': props<{ error: { message: string } }>(),
    'Load Companies After Login Success': props<{ companyProfiles: CompanyProfile[] }>(),
    'Load Companies After Login Failed': props<{ error: { message: string } }>(),
    'Workspace Selected': props<{
      accountId: string;
      companyProfileId: string;
      accountMembershipId: string;
    }>(),
    'Initialize the User': emptyProps(),
  },
});
