import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConversationSerializer } from '@conversation/interfaces/conversation.interface';
import { AtsError, Conversation } from '@conversation/models/conversation.model';
import {
  CONVERSATIONS_QUERY,
  CONVERSATIONS_QUERY_BY_APPLICATION_ID,
} from '@conversation/schemas/conversation.schema';
import { DELETE_MESSAGE } from '@features/messages/schemas/message.schema';
import { selectErecruiterPipe } from '@mkp/account/state';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Store } from '@ngrx/store';
import { UtilsHelpers } from '@shared/helpers/utils.helpers';
import { BaseApolloService } from '@shared/modules/graphql/services/base-apollo.service';
import { FetchResult } from 'apollo-link';
import { EMPTY, Observable, map, of, switchMap, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConversationsDataService extends DefaultDataService<
  Conversation | { error: boolean }
> {
  readonly isErecruiter$ = this.store.pipe(selectErecruiterPipe);

  constructor(
    httpClient: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private baseApolloService: BaseApolloService,
    public utilsHelpers: UtilsHelpers,
    private readonly store: Store
  ) {
    super('Conversation', httpClient, httpUrlGenerator);
  }

  override getAll(): Observable<Conversation[] | AtsError[] | never> {
    return this.isErecruiter$.pipe(
      switchMap((isErecruiter) =>
        isErecruiter
          ? (
              this.baseApolloService.watchQuery(
                CONVERSATIONS_QUERY,
                undefined,
                'no-cache',
                'all'
              ) as Observable<{
                data: { conversations: Conversation[] };
                errors?: [{ message: string }];
              }>
            ).pipe(
              map((result) => {
                const MIDDLEWARE_ERROR_MESSAGE = 'Request failed with status code 500';

                // Because middleware always returns 200 status code we have to check if there is a status code 500 (ATS not responding)
                return result?.errors?.[0].message === MIDDLEWARE_ERROR_MESSAGE
                  ? [{ error: true }]
                  : result.data.conversations
                    ? ConversationSerializer.fromJsonArray(result.data.conversations)
                    : [];
              })
            )
          : EMPTY
      ),

      take(1)
    );
  }

  override getWithQuery(): Observable<Conversation[]> {
    // No filter feature requested yet. This method here avoid calling
    // the super() method
    return of([]);
  }

  override getById(key: string): Observable<Conversation> {
    return (
      this.baseApolloService.watchQuery(CONVERSATIONS_QUERY_BY_APPLICATION_ID, {
        applicationId: key,
      }) as Observable<{ data: { conversationByApplication: Conversation[] } }>
    ).pipe(
      map(({ data }) =>
        data.conversationByApplication
          ? ConversationSerializer.fromJson(data.conversationByApplication)
          : (null as unknown as Conversation)
      ),
      take(1)
    );
  }

  override update(payload: UpdateStr<Conversation>): Observable<Conversation> {
    // NOTE: as there is no Conversation Entity on eRecruiter backend,
    // this update will only affect our store.
    return of(payload.changes as Conversation);
  }

  override delete(id: string): Observable<string | number> {
    return this.baseApolloService
      .mutate<string, { id: string }>(DELETE_MESSAGE, { id })
      .pipe(take(1))
      .pipe(map(({ data }: FetchResult<string>) => data ?? ''));
  }
}
