import { AccountDto } from './account.dto';
import { Account } from './account.model';
import { LegalEntity } from '../legal-entity/legal-entity.model';

// just in case we find an unknown value, should not happen
// change this to 'INTERNAL' after ONE migration
const DEFAULT_ATS = 'ERECRUITER';

export const mapAccountDtoToAccount = (dto: AccountDto, legalEntity: LegalEntity): Account => ({
  id: dto.id,
  name: dto.name,
  legalEntity,
  createdAt: new Date(dto.createdAt),
  settings: {
    ats: {
      name: getAtsName(dto.settings.ats.name),
    },
  },
  computed: {
    canCreateUser: !!dto._links.createAccountMembership,
  },
  _version: dto._version,
});

const getAtsName = (atsName: string): 'ERECRUITER' | 'INTERNAL' => {
  if (!isAtsName(atsName)) {
    console.error(`Unknown ATS: ${atsName}`);
    return DEFAULT_ATS;
  }

  return atsName;
};
const isAtsName = (atsName: string): atsName is 'ERECRUITER' | 'INTERNAL' =>
  ['ERECRUITER', 'INTERNAL'].includes(atsName);
