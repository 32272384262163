import { UserDisplayType } from '@mkp/user/data-access';
import { ProductPlatformGroup } from '@mkp/shared/util-product';
import { TrafficFunnel } from '../enums';
import { platformLogoMapping, trafficFunnelPlatform, userDisplayTypePlatform } from '@shared/enums';

export const getIncentiveTitle = (funnel: TrafficFunnel): string => {
  switch (funnel) {
    case TrafficFunnel.JobScout24:
    case TrafficFunnel.JobScout24Free:
    case TrafficFunnel.JobScout24Purchase:
      return 'ONBOARDING.START.JOBSCOUT24.TITLE';
    case TrafficFunnel.JobsPurchase:
    case TrafficFunnel.JobsFree:
      return 'ONBOARDING.START.JOBS.TITLE';
    case TrafficFunnel.JobUp:
      return 'ONBOARDING.START.JOBUP.TITLE';
    case TrafficFunnel.Tutti:
    default:
      return 'ONBOARDING.START.TUTTI.INCENTIVE.TITLE';
  }
};

export const getIncentiveTitleByType = (userDisplayType: UserDisplayType | undefined): string => {
  switch (userDisplayType) {
    case UserDisplayType.CustomerJobScout24:
      return 'ONBOARDING.START.JOBSCOUT24.TITLE';
    case UserDisplayType.CustomerJobUp:
      return 'ONBOARDING.START.JOBUP.TITLE';
    case UserDisplayType.CustomerJobs:
      return 'ONBOARDING.START.JOBS.TITLE';
    case UserDisplayType.CustomerJobcloud:
    default:
      return 'ONBOARDING.START.TUTTI.INCENTIVE.TITLE';
  }
};

export const incentiveFactory = (funnel: TrafficFunnel): string[] => {
  switch (funnel) {
    case TrafficFunnel.JobScout24Purchase:
      return [
        'ONBOARDING.START.JS24.INCENTIVE.ONE',
        'ONBOARDING.START.JS24.INCENTIVE.TWO',
        'ONBOARDING.START.JS24.INCENTIVE.THREE',
        'ONBOARDING.START.JS24.INCENTIVE.FOUR',
      ];
    case TrafficFunnel.JobScout24Free:
      return [
        'ONBOARDING.START.JS24FREE.INCENTIVE.ONE',
        'ONBOARDING.START.JS24FREE.INCENTIVE.TWO',
        'ONBOARDING.START.JS24FREE.INCENTIVE.THREE',
      ];
    case TrafficFunnel.Tutti:
      return [
        'ONBOARDING.START.TUTTI.INCENTIVE.ONE',
        'ONBOARDING.START.TUTTI.INCENTIVE.TWO',
        'ONBOARDING.START.TUTTI.INCENTIVE.THREE',
        'ONBOARDING.START.TUTTI.INCENTIVE.FOUR',
      ];
    case TrafficFunnel.JobScout24:
      return [
        'ONBOARDING.START.JOBSCOUT24.INCENTIVE.ONE',
        'ONBOARDING.START.JOBSCOUT24.INCENTIVE.TWO',
        'ONBOARDING.START.JOBSCOUT24.INCENTIVE.THREE',
      ];
    case TrafficFunnel.JobsFree:
      return [
        'ONBOARDING.START.JOBSFREE.INCENTIVE.ONE',
        'ONBOARDING.START.JOBSFREE.INCENTIVE.TWO',
        'ONBOARDING.START.JOBSFREE.INCENTIVE.THREE',
      ];
    case TrafficFunnel.JobsPurchase:
      return [
        'ONBOARDING.START.JOBSPURCHASE.INCENTIVE.ONE',
        'ONBOARDING.START.JOBSPURCHASE.INCENTIVE.TWO',
        'ONBOARDING.START.JOBSPURCHASE.INCENTIVE.THREE',
        'ONBOARDING.START.JOBSPURCHASE.INCENTIVE.FOUR',
      ];
    case TrafficFunnel.JobUp:
      return [
        'ONBOARDING.START.JOBUP.INCENTIVE.ONE',
        'ONBOARDING.START.JOBUP.INCENTIVE.TWO',
        'ONBOARDING.START.JOBUP.INCENTIVE.THREE',
        'ONBOARDING.START.JOBUP.INCENTIVE.FOUR',
      ];
    default:
      return [
        'ONBOARDING.START.INCENTIVE.ONE',
        'ONBOARDING.START.INCENTIVE.TWO',
        'ONBOARDING.START.INCENTIVE.THREE',
        'ONBOARDING.START.INCENTIVE.FOUR',
      ];
  }
};

export const getLogoPlatformClass = (
  funnel: TrafficFunnel | undefined,
  displayType: UserDisplayType | undefined
): string[] => {
  const platform: ProductPlatformGroup | undefined = funnel
    ? getPlatformClassByTraficFunnel(funnel as TrafficFunnel)
    : getPlatformClassByDisplayType(displayType);

  return platform
    ? [platformLogoMapping[platform]]
    : Object.values(platformLogoMapping).map((platformLogo) => platformLogo);
};

export const getPlatformClassByDisplayType = (
  displayType: UserDisplayType | undefined
): ProductPlatformGroup | undefined =>
  displayType ? userDisplayTypePlatform[displayType] : undefined;

export const getPlatformClassByTraficFunnel = (
  funnel: TrafficFunnel | undefined
): ProductPlatformGroup | undefined => (funnel ? trafficFunnelPlatform[funnel] : undefined);

export const getButtonPlatformClass = (
  platform: ProductPlatformGroup | string | undefined
): string[] => [platform ? `button-color-${platform}` : ''];
