import { HttpErrorResponse } from '@angular/common/http';
import { Application } from '@mkp/application/data-access';
import { createActionGroup, props } from '@ngrx/store';

export const applicationApiActions = createActionGroup({
  source: 'Application Api',
  events: {
    'Route Application Loaded Success': props<{ application: Application }>(),
    'Route Application Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Applications Loaded Success': props<{ applications: Application[] }>(),
    'Applications Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'More Applications Loaded Success': props<{ applications: Application[] }>(),
    'More Applications Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'One More Application Loaded Success': props<{ applications: Application[] }>(),
    'One More Application Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Delete Success': props<{
      applicationId: string;
      applicationFullName: string;
    }>(),
    'Application Delete Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationFullName: string;
    }>(),
    'Application Delete Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),

    'Application Status Change Success': props<{ application: Application }>(),
    'Application Status Change Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
      statusId: string;
    }>(),
    'Application Status Change Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),
  },
});
