import { LegalEntity, LegalEntityDto } from '@mkp/account/data-access';
import { mapLegalEntityDtoToLegalEntity } from 'libs/account/data-access/src/lib/legal-entity/legal-entity.mapper';

/**
 * @deprecated migrate to a resource in the libs folder
 */
export class LegalEntitySerializer {
  fromJson(legalEntityDto: LegalEntityDto): LegalEntity {
    return mapLegalEntityDtoToLegalEntity(legalEntityDto);
  }

  toJson(legalEntity: LegalEntity): LegalEntityDto {
    // never used
    return {} as LegalEntityDto;
  }
}
