import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Product,
  ProductCode,
  ProductOfferingBenefit,
  ProductOfferingPlatform,
  ProductPlatformGroup,
} from '@mkp/shared/util-product';
import {
  ProductCardComponent,
  ProductCardsSkeletonComponent,
} from '@mkp/product/feature-product-shared';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'mkp-product-display-card-list',
  templateUrl: './product-display-card-list.component.html',
  styleUrl: './product-display-card-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, ProductCardComponent, ProductCardsSkeletonComponent],
})
export class ProductDisplayCardListComponent {
  @Input({ required: true }) products!: Product[];
  @Input({ required: true }) platforms!: ProductOfferingPlatform[];
  @Input({ required: true }) benefits!: ProductOfferingBenefit[];
  @Input({ required: true }) isReadMode!: boolean;
  @Input({ required: true }) isProductLoading: ProductCode | false = false;
  @Input({ required: true }) activePlatformGroup!: ProductPlatformGroup;
  @Output() handleSelectedProduct: EventEmitter<ProductCode> = new EventEmitter();
}
