import { gql } from '@apollo/client/core';
const conversationFragment = gql`
  fragment conversationFragment on Conversation {
    id
    applicationId
    candidateName
    candidateId
    vacancyId
    jobPosition
    lastSubject
    lastBody
    lastMessageTime
    isRead
  }
`;

const candidatePhotoFragment = gql`
  fragment candidatePhotoFragment on Document {
    id
    fileName
    mimeType
    content
  }
`;

export const CONVERSATIONS_QUERY = gql`
  query conversations {
    conversations(withMessages: false) {
      ...conversationFragment
      candidatePhoto {
        ...candidatePhotoFragment
      }
    }
  }
  ${conversationFragment}
  ${candidatePhotoFragment}
`;

export const CONVERSATIONS_QUERY_BY_APPLICATION_ID = gql`
  query conversationByApplication($applicationId: String!) {
    conversationByApplication(applicationId: $applicationId) {
      ...conversationFragment
    }
  }
  ${conversationFragment}
`;

export const READ_CONVERSATION_BY_ID = gql`
  query readConversationById($id: String!) {
    readConversationById(id: $id)
  }
`;
