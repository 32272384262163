import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { authApiActions } from '@mkp/auth/actions';
import { selectLoggedInUser } from '@app/features/user/store/selectors/user.selectors';
import { IntercomService, mapUserAndSettingsToIntercomUser } from '@core/services';
import { ClaimCompanyActions } from '@mkp/onboarding/feature-claim-company/actions';
import { selectIntercomHash } from '../selectors';

@Injectable()
export class IntercomEffects {
  readonly updateIntercomUserData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authApiActions.fetchAccessTokenSuccess),
        switchMap((token) =>
          this.store.select(selectLoggedInUser).pipe(
            filter(Boolean),
            map((user) => [token.intercomHash, user] as const)
          )
        ),
        exhaustMap(([intercomHash, user]) => {
          const intercomUser = mapUserAndSettingsToIntercomUser(user, intercomHash);
          return this.intercomService.update(intercomUser);
        })
      ),
    { dispatch: false }
  );

  readonly addCompanyToIntercomUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimCompanyActions.companyClaimedSuccess),
        withLatestFrom(
          this.store.select(selectLoggedInUser),
          this.store.select(selectIntercomHash)
        ),
        exhaustMap(([{ company }, user, intercomHash]) => {
          const intercomUser = mapUserAndSettingsToIntercomUser(user, intercomHash);
          return this.intercomService.update({
            ...intercomUser,
            company: {
              company_id: company.externalId,
              name: company.name,
              industry: company.industry.en,
              size: company.employeeCountFrom,
            },
          });
        }),
        // redirect after intercom update
        delay(100),
        tap(() => redirect())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private store: Store,
    private intercomService: IntercomService
  ) {}
}

const redirect = () => {
  window.location.replace('/');
};
