import { FileUpload } from '@shared/models/file-upload.model';
import * as uploadActions from '@app/store/actions/upload.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface UploadState extends EntityState<FileUpload> {
  uploading: boolean;
}

export const adapter: EntityAdapter<FileUpload> = createEntityAdapter<FileUpload>();
export const initialState: UploadState = adapter.getInitialState({
  uploading: false,
});

export function uploadReducer(state = initialState, action: uploadActions.Actions): UploadState {
  switch (action.type) {
    case uploadActions.ActionTypes.UPLOAD_RESET: {
      return adapter.removeAll(state);
    }

    case uploadActions.ActionTypes.UPLOAD_REQUEST:
      return adapter.addOne(action.payload.file, {
        ...state,
        uploading: true,
      });
    case uploadActions.ActionTypes.UPLOAD_ADD_FAILED: {
      return adapter.addOne(action.payload, {
        ...state,
      });
    }
    case uploadActions.ActionTypes.UPLOAD_STARTED:
    case uploadActions.ActionTypes.UPLOAD_PROGRESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        uploading: true,
      });
    }
    case uploadActions.ActionTypes.UPLOAD_FAILURE:
    case uploadActions.ActionTypes.UPLOAD_COMPLETED: {
      return adapter.updateOne(action.payload, {
        ...state,
        uploading: false,
      });
    }
    case uploadActions.ActionTypes.UPLOAD_FILE_DELETE: {
      return adapter.removeOne(action.payload.id, {
        ...state,
      });
    }
    default: {
      return state;
    }
  }
}

export const getUploading = (state: UploadState) => state.uploading;

export const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();
