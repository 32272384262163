import { ComponentType, NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ModalComponent, ModalConfig } from '@mkp/shared/ui-library';
import { map } from 'rxjs';
import { take, tap } from 'rxjs/operators';

/**
 * GOOD ONE TO USE
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  public openDialog(
    config: ModalConfig
  ): MatDialogRef<ModalComponent, { confirm?: boolean; dismiss?: boolean }> {
    const dialogConfig = this.getDialogConfig(config);
    const modalDialog = this.dialog.open(ModalComponent, dialogConfig);

    modalDialog
      .afterClosed()
      .pipe(
        take(1),
        map((result) => ({ confirm: false, ...result })),
        tap(({ confirm, dismiss }) => {
          if (confirm && typeof config.action === 'function') {
            config.action();
          }

          if (!confirm && dismiss && typeof config.dismissAction === 'function') {
            config.dismissAction();
          }
        })
      )
      .subscribe();

    return modalDialog;
  }

  private getDialogConfig(config: ModalConfig) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog';
    dialogConfig.data = { ...config };
    dialogConfig.scrollStrategy = new NoopScrollStrategy();
    return dialogConfig;
  }

  /**
   * charging this method to open the modal, so it's easier to refactor later
   * @param component
   * @param config
   */
  open<T, U>(component: ComponentType<T>, config: MatDialogConfig) {
    return this.dialog.open<T, U>(component, {
      scrollStrategy: new NoopScrollStrategy(),
      ...config,
    });
  }
}
