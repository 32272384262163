import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { State } from '@app/store';
import { AppConfig } from '@config/app.config';
import { AuthFacade } from '@mkp/auth/util';
import { IntercomService } from '@core/services';
import { SessionStorageService } from '@core/services/session-storage.service';
import { HomePageActions } from '@mkp/core/feature-home/actions';
import { DemoProductsComponent, DemoSnapshotsComponent, ReplacePicturePipe } from '@mkp/core/ui';
import {
  ActionState,
  Button,
  ButtonColor,
  ButtonComponent,
  ButtonSize,
  SnackbarService,
  TabConfig,
  TabItemComponent,
  TabSize,
  TabsComponent,
} from '@mkp/shared/ui-library';
import { BreakpointObserverService } from '@mkp/shared/util-device';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/modules/material.module';
import { SkeletonLoaderModule } from '@shared/modules/skeleton-loader/skeleton-loader.module';
import { selectInterfaceLanguage } from '@store/selectors';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mkp-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent,
    MaterialModule,
    InlineSVGModule,
    TranslateModule,
    SkeletonLoaderModule,
    TabsComponent,
    TabItemComponent,
    DemoProductsComponent,
    DemoSnapshotsComponent,
    ReplacePicturePipe,
    DataTestDirective,
    NgOptimizedImage,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None, // to access innerHtml style
})
export class HomeComponent implements OnInit {
  private readonly auth = inject(AuthFacade);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly intercomService = inject(IntercomService);
  private readonly notificationsService = inject(SnackbarService);
  private readonly router = inject(Router);
  private readonly sessionStorageService = inject(SessionStorageService);
  private readonly store = inject(Store<State>);
  public readonly breakpointObserverService = inject(BreakpointObserverService);
  public readonly destroy = inject(DestroyRef);

  /**
   * @deprecated
   */
  public ladda$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isMobile$ = this.breakpointObserverService.isMobile$;
  readonly language$ = this.store.select(selectInterfaceLanguage);
  readonly primaryRegisterButtonConfig: Button = {
    color: ButtonColor.Primary,
    size: ButtonSize.Medium,
    fullWidth: true,
  };
  readonly secondaryRegisterButtonConfig: Button = {
    size: ButtonSize.Medium,
    fullWidth: false,
    color: ButtonColor.Secondary,
  };
  readonly tabsConfig: TabConfig = {
    size: TabSize.Large,
  };
  readonly tabs = [
    {
      title: 'HOME.TABS.JOB_ADS',
      index: 0,
      dataTest: 'job-ads',
      imageSrc: 'job-ads',
    },
    {
      title: 'HOME.TABS.VACANCY_PROFILE',
      index: 1,
      dataTest: 'vacancy-profile',
      imageSrc: 'vacancy-profile',
    },
    {
      title: 'HOME.TABS.CANDIDATE_PROFILE',
      index: 2,
      dataTest: 'candidate-profile',
      imageSrc: 'candidate',
    },
    {
      title: 'HOME.TABS.COMPANY_PROFILE',
      index: 3,
      dataTest: 'company-profile',
      imageSrc: 'company',
    },
  ];
  readonly homepageImageUrl = 'assets/images/homepage/redesign';
  contactUsButtonConfig: Button = {
    size: ButtonSize.Medium,
  };

  ngOnInit() {
    this.store.dispatch(HomePageActions.enter());
    // this.auth.isAuthenticated$
    //   .pipe(
    //     tap((authenticated) => (authenticated ? this.auth.initialize() : noop())),
    //     takeUntilDestroyed(this.destroy)
    //   )
    //   .subscribe();

    this.breakpointObserverService.isMobile$
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((isMobile) => {
        const btnColor = isMobile ? ButtonColor.Secondary : ButtonColor.Primary;

        this.contactUsButtonConfig = {
          ...this.contactUsButtonConfig,
          color: btnColor,
        };

        this.cd.markForCheck();
      });

    // JMP-4357 - auth0 refreshes the page after a logout, look into session to see if logout was forced because of an error
    if (this.sessionStorageService.get('forcedLogout')) {
      this.handleForcedLogout();
    }
  }

  openIntercom() {
    this.intercomService.show();
  }

  private handleForcedLogout() {
    this.sessionStorageService.remove('forcedLogout');
    this.router
      .navigateByUrl(AppConfig.routes.error500)
      .then(() =>
        this.notificationsService.show('REQUEST_TIMEOUT_MESSAGE', { state: ActionState.Error })
      );
  }
}
