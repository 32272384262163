import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(public httpClient: HttpClient) {}

  /**
   * @describe upload files and get the upload status
   * @param data file to upload
   */
  public upload(endpoint: string, file: File, queryData?: string) {
    const formData = new FormData();
    formData.append('blobFile', new Blob([file]));
    const options = {
      reportProgress: true,
    };
    const req = new HttpRequest(
      'POST',
      `${environment.api.core}/${endpoint}?${queryData}`,
      formData,
      options
    );
    return this.httpClient.request(req);
  }
}
