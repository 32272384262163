import { UserDisplayType } from '@mkp/user/data-access';

export enum TrafficFunnelDefault {
  Default = 'default',
}

export enum TrafficFunnel {
  JobScout24 = 'jobscout24',
  JobScout24Free = 'JS24free',
  JobScout24Purchase = 'JS24purchase',
  JobsFree = 'jobsfree',
  JobsPurchase = 'jobspurchase',
  JobUp = 'jobup',
  Tutti = 'tutti',
}

export type TrafficFunnelWithDefault = TrafficFunnel | TrafficFunnelDefault;

export const displayTypePerFunnel = {
  [TrafficFunnel.JobsFree]: UserDisplayType.CustomerJobs,
  [TrafficFunnel.JobsPurchase]: UserDisplayType.CustomerJobs,
  [TrafficFunnel.JobScout24]: UserDisplayType.CustomerJobScout24,
  [TrafficFunnel.JobScout24Free]: UserDisplayType.CustomerJobScout24,
  [TrafficFunnel.JobScout24Purchase]: UserDisplayType.CustomerJobScout24,
  [TrafficFunnel.JobUp]: UserDisplayType.CustomerJobUp,
  [TrafficFunnel.Tutti]: UserDisplayType.CustomerJobScout24,
} satisfies { [key in TrafficFunnel]: UserDisplayType };
