import { Account } from '@mkp/account/data-access';
import { createActionGroup, props } from '@ngrx/store';

export const accountApiActions = createActionGroup({
  source: 'Account/API',
  events: {
    'Load Account Success': props<{ account: Account }>(),
    'Load Account Failure': props<{ error: { message: string } }>(),
    'Load Accounts Success': props<{ accounts: Account[] }>(),
    'Load Accounts Failure': props<{ error: { message: string } }>(),
    'Load Account By Legal Entity External Id Success': props<{
      account: Account;
      selectedAccountId: string;
    }>(),
    'Load Account By Legal Entity External Id Failure': props<{ error: { message: string } }>(),
  },
});
