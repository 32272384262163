import { AccountDto } from '../account/account.dto';
import { LegalEntityService } from '@company/services/legal-entity.service';
import { map, Observable, OperatorFunction, pipe, switchMap, take } from 'rxjs';
import { Account, LegalEntity, mapAccountDtoToAccount } from '@mkp/account/data-access';

export const addLegalEntityToAccountDto = (
  legalEntityService: LegalEntityService
): OperatorFunction<AccountDto, Account> =>
  pipe(
    switchMap((account: AccountDto) =>
      fetchLegalEntitiesAndMapToAccount([account], legalEntityService)
    ),
    map((accounts) => accounts[0])
  );

export const addLegalEntityToAccountsDto = (
  legalEntityService: LegalEntityService
): OperatorFunction<AccountDto[], Account[]> =>
  pipe(
    switchMap((accounts: AccountDto[]) =>
      fetchLegalEntitiesAndMapToAccount(accounts, legalEntityService)
    )
  );

const fetchLegalEntitiesAndMapToAccount = (
  accounts: AccountDto[],
  legalEntityService: LegalEntityService
): Observable<Account[]> =>
  legalEntityService.list({ filter: getLegalEntityFilter(accounts) }).pipe(
    map(({ _embedded }) => _embedded?.results ?? []),
    map((legalEntities) => mapAccountDtosToAccounts(accounts, legalEntities)),
    take(1)
  );

const getLegalEntityFilter = (accounts: AccountDto[]): string =>
  accounts.map((account) => `id==${account.legalEntityId}`).join(',');

const mapAccountDtosToAccounts = (
  accounts: AccountDto[],
  legalEntities: LegalEntity[]
): Account[] => {
  const legalEntityMap = new Map(legalEntities.map((legalEntity) => [legalEntity.id, legalEntity]));
  return accounts.map((account) =>
    mapAccountDtoToAccount(account, legalEntityMap.get(account.legalEntityId) as LegalEntity)
  );
};
