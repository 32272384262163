import { SafeResourceUrl } from '@angular/platform-browser';
import { Document } from '@documents/models';
import { Message } from '@features/messages/models/message.model';

export class IConversation {
  public id?: string | null = null;
  public applicationId?: string | null = null;
  public candidateName: string | null = null;
  public candidateId: string | null = null;
  public vacancyId?: string | null = null;
  public jobPosition?: string | null = null;
  public lastSubject: string | null = null;
  public lastBody?: string | null = null;
  public lastMessageTime: Date | null = null;
  public isRead: boolean | null = null;
  public messages?: Message[] = [];
}

export class Conversation {
  public id?: string;
  public applicationId?: string;
  public candidateName!: string;
  public candidateId!: string;
  public candidatePhoto!: Document;
  public candidatePhotoUrl!: SafeResourceUrl;
  public vacancyId?: string;
  public jobPosition?: string;
  public lastSubject!: string;
  public lastBody?: string;
  public lastMessageTime!: Date;
  public isRead!: boolean;
  public messages?: Message[];
  public checked = false;

  constructor(partial: Partial<Conversation>) {
    Object.assign(this, partial);
  }
}

export interface AtsError {
  error: boolean;
}
