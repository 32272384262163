import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router, Scroll } from '@angular/router';
import { LocalStorageKeys } from '@app/config/app.config';
import { selectIsNewVersionAvailable } from '@mkp/core/state';
import { isChunkLoadError } from '@mkp/core/util-sentry';
import { SnackbarService } from '@mkp/shared/ui-library';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { State } from '../reducers';
import { selectIsSelectedAccountBeingVerified } from '@user/store/selectors/user.selectors';

@Injectable()
export class RouterEffects {
  readonly redirectUri$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        filter(() => isLoginLandingPage(this.window.location)),
        filter(hasRedirectUriInLocalStorage),
        map(() => localStorage.getItem(LocalStorageKeys.redirectUri)),
        tap(() => localStorage.removeItem(LocalStorageKeys.redirectUri)),
        tap((redirectUri) => this.router.navigateByUrl(redirectUri))
      ),
    { dispatch: false }
  );

  readonly listenForNavigationError$ = createEffect(
    () =>
      this.router.events.pipe(
        filter(isNavigationError),
        filter((event) => isChunkLoadError(event.error)),
        tap((event) => this.window.location.replace(event.url))
      ),
    { dispatch: false }
  );

  // on navigation: check the version-store for a new version. If there is one: update the app
  readonly listenForNewVersion$ = createEffect(
    () =>
      this.router.events.pipe(
        filter(isNavigationStart),
        concatLatestFrom(() => this.store.select(selectIsNewVersionAvailable)),
        filter(([, isNewVersionAvailable]) => isNewVersionAvailable),
        tap(([event]) => this.window.location.replace(event.url))
      ),
    { dispatch: false }
  );

  // if user is being verified, we want to display a snackbar when customer service validates their account
  readonly listenForAccountVerification$ = createEffect(
    () =>
      this.router.events.pipe(
        filter(isNavigationEnd),
        switchMap(() => this.store.select(selectIsSelectedAccountBeingVerified)),
        pairwise(),
        tap(([beingVerified1, beingVerified2]) => {
          if (beingVerified1 && !beingVerified2) {
            this.snackbarService.show('ACCOUNT_VERIFICATION.SUCCESS.MESSSAGE');
          }
        })
      ),
    { dispatch: false }
  );

  // detect navigation events containing a "fragment" option, and scroll to the anchor. For example:
  //  - salary experiment modal will scroll to salary fields, in product-options.effects
  readonly scrollToAnchor$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((e): e is Scroll => e instanceof Scroll),
        tap((e: Scroll) => {
          if (e.anchor) {
            setTimeout(() => {
              this.viewportScroller.scrollToAnchor(e.anchor);
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly window: Window,
    private readonly store: Store<State>,
    private readonly snackbarService: SnackbarService,
    private readonly viewportScroller: ViewportScroller
  ) {}
}

const isLoginLandingPage = (localLocation: Location): boolean =>
  localLocation.pathname === '/' &&
  (localLocation.search.includes('code=') || localLocation.search.includes('error=')) &&
  localLocation.search.includes('state=');
const hasRedirectUriInLocalStorage = (): boolean =>
  localStorage.getItem(LocalStorageKeys.redirectUri) != null;
const isNavigationStart = (event: unknown): event is NavigationStart =>
  event instanceof NavigationStart;
const isNavigationError = (event: unknown): event is NavigationError =>
  event instanceof NavigationError;
const isNavigationEnd = (event: unknown): event is NavigationEnd => event instanceof NavigationEnd;
