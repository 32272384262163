import { inject } from '@angular/core';
import { selectVacancyIdFromRoute } from '@app/features/vacancy/store/selectors/vacancy.selectors';
import {
  ApplicationResource,
  ApplicationStatusResource,
  ApplicationStep,
  FetchApplicationPayload,
  mapApplicationDtoToModel,
} from '@mkp/application/data-access';
import {
  applicationApiActions,
  applicationExistGuardActions,
  applicationPageActions,
  applicationStatusApiActions,
  applicationStatusesGuardActions,
} from '@mkp/application/state/actions';
import { documentApiActions } from '@mkp/document/state/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, of, OperatorFunction, switchMap } from 'rxjs';
import { selectApplicationStatuses } from './application.selectors';

export const loadRouteApplication = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationExistGuardActions.canActivate),
      map(({ id }) => id),
      filter(Boolean),
      exhaustMap((id) =>
        applicationResource.getById(id).pipe(
          map(mapApplicationDtoToModel),
          map((application) =>
            applicationApiActions.routeApplicationLoadedSuccess({ application })
          ),
          catchError((error) =>
            of(applicationApiActions.routeApplicationLoadedFailure({ errorMessage: error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const loadApplications = createEffect(
  (
    actions$ = inject(Actions),
    applicationResource = inject(ApplicationResource),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(applicationPageActions.opened, applicationPageActions.tabChanged),
      concatLatestFrom(() => [
        store.select(selectApplicationStatuses),
        store.select(selectVacancyIdFromRoute),
      ]),
      map(([{ step, limit, offset }, statuses, vacancyId]) => ({
        applicationStatusId: statuses.find((status) => status.step === step)?.id,
        vacancyId,
        offset,
        limit,
      })),
      filter(isFetchApplicationPayload),
      switchMap(({ applicationStatusId, vacancyId, offset, limit }) =>
        applicationResource
          .fetchApplicationsByStatus({ applicationStatusId, vacancyId, offset, limit })
          .pipe(
            map((applications) =>
              applicationApiActions.applicationsLoadedSuccess({ applications })
            ),
            catchError((error) =>
              of(applicationApiActions.applicationsLoadedFailure({ errorMessage: error }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const loadApplicationStatuses = createEffect(
  (actions$ = inject(Actions), applicationStatusResource = inject(ApplicationStatusResource)) => {
    const store = inject(Store);
    return actions$.pipe(
      ofType(applicationStatusesGuardActions.canActivate),
      concatLatestFrom(() => store.select(selectApplicationStatuses)),
      filter(([, statuses]) => !statuses.length),
      exhaustMap(() =>
        applicationStatusResource.fetchApplicationStatuses().pipe(
          map((applicationStatuses) =>
            applicationStatusApiActions.applicationStatusesLoadedSuccess({ applicationStatuses })
          ),
          catchError((error) =>
            of(
              applicationStatusApiActions.applicationStatusesLoadedFailure({
                errorMessage: error,
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const loadMoreApplications = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        applicationPageActions.loadMoreApplications,
        applicationPageActions.loadMoreApplicationsToFindRouteApplication
      ),
      loadOneOrMoreApplications(
        applicationApiActions.moreApplicationsLoadedSuccess,
        applicationApiActions.moreApplicationsLoadedFailure
      )
    );
  },
  { functional: true }
);

export const loadOneMoreApplication = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(applicationPageActions.loadOneMoreApplication),
      loadOneOrMoreApplications(
        applicationApiActions.oneMoreApplicationLoadedSuccess,
        applicationApiActions.oneMoreApplicationLoadedFailure
      )
    );
  },
  { functional: true }
);

export const deleteApplication = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(documentApiActions.documentsDeletedSuccess),
      exhaustMap(({ applicationId, applicationFullName }) =>
        applicationResource.deleteApplication(applicationId).pipe(
          map(() =>
            applicationApiActions.applicationDeleteSuccess({
              applicationId,
              applicationFullName,
            })
          ),
          catchError((errorMessage) => {
            if (errorMessage.status === 404) {
              return of(
                applicationApiActions.applicationDeleteNotFound({
                  errorMessage,
                  applicationId,
                })
              );
            }
            return of(
              applicationApiActions.applicationDeleteFailure({ errorMessage, applicationFullName })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

export const updateApplicationStatus = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(
        applicationPageActions.userClickedOnStatusInActionButton,
        applicationPageActions.userClickedOnStatusInDropdown
      ),
      exhaustMap(({ applicationId, statusId, _version }) =>
        applicationResource.updateApplicationStatus(applicationId, statusId, _version).pipe(
          filter(Boolean),
          map((application) =>
            applicationApiActions.applicationStatusChangeSuccess({ application })
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(
                applicationApiActions.applicationStatusChangeNotFound({
                  errorMessage: error,
                  applicationId,
                })
              );
            }
            return of(
              applicationApiActions.applicationStatusChangeFailure({
                errorMessage: error,
                applicationId,
                statusId,
              })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

const isFetchApplicationPayload = (payload: {
  applicationStatusId: string | undefined;
  vacancyId: string | undefined;
  offset: number;
  limit: number;
}): payload is FetchApplicationPayload =>
  Boolean(payload.applicationStatusId) && Boolean(payload.vacancyId);

type LoadOneOrMoreApplicationsSuccessActionCreator =
  | typeof applicationApiActions.oneMoreApplicationLoadedSuccess
  | typeof applicationApiActions.moreApplicationsLoadedSuccess;
type LoadOneOrMoreApplicationsFailureActionCreator =
  | typeof applicationApiActions.oneMoreApplicationLoadedFailure
  | typeof applicationApiActions.moreApplicationsLoadedFailure;
type LoadOneOrMoreApplicationsAction = ReturnType<
  LoadOneOrMoreApplicationsSuccessActionCreator | LoadOneOrMoreApplicationsFailureActionCreator
>;

const loadOneOrMoreApplications = (
  successAction: LoadOneOrMoreApplicationsSuccessActionCreator,
  failureAction: LoadOneOrMoreApplicationsFailureActionCreator,
  store = inject(Store),
  applicationResource = inject(ApplicationResource)
): OperatorFunction<
  {
    step: ApplicationStep;
    limit: number;
    offset: number;
  },
  LoadOneOrMoreApplicationsAction
> => {
  return (source) =>
    source.pipe(
      concatLatestFrom(() => [
        store.select(selectApplicationStatuses),
        store.select(selectVacancyIdFromRoute),
      ]),
      map(([{ step, limit, offset }, statuses, vacancyId]) => ({
        applicationStatusId: statuses.find((status) => status.step === step)?.id,
        vacancyId,
        offset,
        limit,
      })),
      filter(isFetchApplicationPayload),
      switchMap(({ applicationStatusId, vacancyId, offset, limit }) =>
        applicationResource
          .fetchApplicationsByStatus({
            applicationStatusId,
            vacancyId,
            offset,
            limit,
          })
          .pipe(
            map((applications) => successAction({ applications })),
            catchError((error) => of(failureAction({ errorMessage: error })))
          )
      )
    );
};
