import { createActionGroup, props } from '@ngrx/store';

export const createVacancyWrapperActions = createActionGroup({
  source: 'Create Vacancy Wrapper',
  events: {
    'Go Back Button Clicked': props<{ stepIndex: number }>(),
    'Active Step Changed': props<{
      stepIndex: number;
      nbSuggestions: number;
      vacancyTemporaryId: string;
      vacancyId: string;
    }>(),
    'Suggest Button Clicked': props<{
      vacancyId: string | undefined;
      vacancyTemporaryId: string | undefined;
    }>(),
    'Cancel Suggest Button Clicked': props<{
      vacancyId: string | undefined;
      vacancyTemporaryId: string | undefined;
    }>(),
  },
});
