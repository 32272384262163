import { accountMembershipFeature as fromAccountMembership } from './account-membership.reducer';
import { User } from '@user/shared/models';
import { createSelector } from '@ngrx/store';

export const {
  selectAll: selectList,
  selectEntities: selectAccountMemberships,
  selectTotalCount,
  selectLinks,
  selectFilter,
  selectLoaded,
  selectLoading,
  selectFeatureState,
  selectSelectedEntity: selectSelectedAccountMembership,
} = fromAccountMembership.selectors;

export const selectAccountMembershipByUserId = (userIdToFind: User['id']) =>
  createSelector(selectList, (memberships) =>
    memberships.find(({ userId }) => userId === userIdToFind)
  );

export const selectActiveAccountMembershipCount = createSelector(
  selectFeatureState,
  (state) => state.totalCount
);

export const selectHasActiveAccountMembership = createSelector(
  selectActiveAccountMembershipCount,
  (activeAccountMembershipCount) => !!activeAccountMembershipCount
);
