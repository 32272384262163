import { inject } from '@angular/core';
import { authActions } from '@mkp/auth/actions';
import { getMessageError } from '@app/core/models';
import { legalEntityGuardActions } from '@app/store/actions';
import {
  AccountResource,
  isActiveLegalEntity,
} from '@mkp/account/data-access';
import { accountApiActions } from '@mkp/account/data-access/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { selectSelectedAccount } from './account.selectors';
import { AccountMembership } from '@mkp/account-membership/data-access';
import { LegalEntityService } from '@company/services/legal-entity.service';
import { addLegalEntityToAccountDto, addLegalEntityToAccountsDto } from '../../../../data-access/src/lib/helpers/helpers';

// Fetch list of all user accounts
export const fetchAccountsAfterLogin = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityService)
  ) =>
    actions$.pipe(
      ofType(authActions.loadActiveAccountMembershipsSuccess),
      filter(({ accountMemberships }) => accountMemberships.length > 0),
      switchMap(({ accountMemberships }) =>
        accountResource.getWithQuery(getActiveAccountsFilter(accountMemberships)).pipe(
          map(({ _embedded: { results: accounts } }) => accounts),
          addLegalEntityToAccountsDto(legalEntityResource),
          map((accounts) => authActions.loadAccountsAfterLoginSuccess({ accounts })),
          catchError((error: unknown) =>
            of(
              authActions.loadAccountsAfterLoginFailed({
                error: getMessageError(error, 'fetchAccountsAfterLogin'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

// when legal-entity is inactive, we want to regularly check if legal-entity becomes active
// this is due to the fact that customer service usually activates an account within 5min
// we want reactive UI when this happens
export const checkActiveLegalEntity = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    legalEntityResource = inject(LegalEntityService),
    accountResource = inject(AccountResource)
  ) =>
    actions$.pipe(
      // this guard is not blocking users, its only purpose is to trigger this refresh
      ofType(legalEntityGuardActions.canActivate),
      concatLatestFrom(() => store.select(selectSelectedAccount)),
      map(([, account]) => account),
      filter(Boolean),
      filter((account) => !isActiveLegalEntity(account.legalEntity)),
      switchMap(({ id: accountId }) =>
        accountResource.getById(accountId).pipe(
          addLegalEntityToAccountDto(legalEntityResource),
          map((account) => accountApiActions.loadAccountSuccess({ account })),
          catchError((error: unknown) =>
            of(
              accountApiActions.loadAccountFailure({
                error: getMessageError(error, 'checkActiveLegalEntity'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

const getActiveAccountsFilter = (activeAccountMemberships: AccountMembership[]) => ({
  filter: activeAccountMemberships
    .map((accountMembership) => `id==${accountMembership.accountId}`)
    .join(','),
});
