import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';

import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ProductCardComponent,
  ProductCardsSkeletonComponent,
  ProductPlatformCellComponent,
  ProductTableSkeletonComponent,
} from '@mkp/product/feature-product-shared';
import { AssetComponent } from '@mkp/shared/ui-asset';
import { Button, ButtonColor, ButtonComponent, ButtonSize } from '@mkp/shared/ui-library';
import { BreakpointObserverService } from '@mkp/shared/util-device';
import {
  Product,
  ProductCode,
  ProductOfferingBenefit,
  ProductOfferingPlatform,
  ProductPlatformGroup,
  ScrollDirection,
} from '@mkp/shared/util-product';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  ProductStickyHeaderComponent,
  ProductTableColumnComponent,
  ProductTableColumnSkeletonComponent,
  ProductTableRowHeaderFeatureComponent,
} from './components';
import { InViewportNotifierDirective, ScrollPosition, ScrollToNextDirective } from './directives';
import { ScrollToNextService } from './services/scroll-to-next.service';

const DEFAULT_SCROLL_CONFIG: Button = {
  size: ButtonSize.Small,
  color: ButtonColor.Tertiary,
};

@Component({
  selector: 'mkp-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./style/product-offering.style.scss', './product-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AssetComponent,
    ButtonComponent,
    NgIf,
    NgFor,
    AsyncPipe,
    DataTestDirective,
    InViewportNotifierDirective,
    LetDirective,
    ProductCardComponent,
    ProductCardsSkeletonComponent,
    ProductTableColumnSkeletonComponent,
    ProductStickyHeaderComponent,
    ProductTableColumnComponent,
    ProductTableSkeletonComponent,
    ProductTableRowHeaderFeatureComponent,
    ScrollToNextDirective,
    TranslateModule,
    ProductPlatformCellComponent,
  ],
  providers: [ScrollToNextService],
})
export class ProductTableComponent implements AfterViewChecked {
  readonly scrollConfig: Record<ScrollPosition, Button> = {
    left: DEFAULT_SCROLL_CONFIG,
    right: DEFAULT_SCROLL_CONFIG,
  } as const;

  public isStickyHeaderVisible$ = new BehaviorSubject<boolean>(false);

  @Input({ required: true }) platforms!: ProductOfferingPlatform[];
  @Input({ required: true }) benefits!: ProductOfferingBenefit[];
  @Input({ required: true }) products!: Product[];
  @Input({ required: true }) isReadMode!: boolean;
  @Input({ required: true }) activePlatformGroup!: ProductPlatformGroup;
  @Input({ required: true }) hideStickyHeader!: boolean;
  @Input({ required: true }) isProductLoading: ProductCode | false = false;
  @Input({ required: true }) productAlternativeUI!: boolean;

  @Output() productSelected: EventEmitter<Product['code']> = new EventEmitter();
  @Output() productScrolled: EventEmitter<ScrollDirection> = new EventEmitter();

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  readonly showLabel$: Observable<boolean> = this.breakpointObserverService.isTabletPortrait$.pipe(
    map((isTabletPortrait) => !isTabletPortrait)
  );

  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  ngAfterViewChecked(): void {
    // this is meant for displaying the scroll button after the first cycle
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    });
  }
}
