import { DEFAULT_LANGUAGE, LanguageCode } from '@mkp/shared/data-access';
import { UserDisplayType, UserTitle } from '@mkp/user/data-access';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  emailVerified: boolean;
  title: UserTitle;
  profilePictureMediaApiId: string | null;
  phoneNumber: string | null;
  settings: {
    displayType: UserDisplayType;
    marketingConsentAcceptedAt: Date | null;
    publicationAiUsageConsent: boolean | null;
  };
  language: LanguageCode;
  _version: string;
}

export const defaultUser: User = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  title: UserTitle.Ms,
  emailVerified: false,
  profilePictureMediaApiId: '',
  phoneNumber: '',
  settings: {
    displayType: UserDisplayType.CustomerJobs,
    marketingConsentAcceptedAt: null,
    publicationAiUsageConsent: null,
  },
  language: DEFAULT_LANGUAGE.code,
  _version: '',
};

interface UpdateSettings {
  settings?: {
    marketingConsent?: boolean;
    publicationAiUsageConsent?: boolean;
  };
}

export type UpdateUser = Partial<Omit<User, 'settings'>> &
  Pick<User, 'id' | '_version'> &
  UpdateSettings;

interface CreateSettings {
  settings: { marketingConsent: boolean };
}

export type CreateUser = Pick<
  User,
  | 'title'
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'email'
  | 'profilePictureMediaApiId'
  | 'language'
  | '_version'
> &
  CreateSettings;

export const isUpdateUser = (user: UpdateUser | CreateUser): user is UpdateUser =>
  (user as UpdateUser)?.id !== undefined;
