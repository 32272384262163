import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Document, DocumentType } from '@app/features/documents/models';
import { DocumentService } from '@app/features/documents/services/document.service';
import { selectDocumentsByCandidateId } from '@app/features/documents/store';
import * as uploadActions from '@app/store/actions/upload.actions';
import * as documentActions from '@documents/store/actions/documents.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Action, Store } from '@ngrx/store';
import {
  FileUpload,
  ProgressColor,
  ProgressMode,
  UploadStatus,
} from '@shared/models/file-upload.model';
import { UploadService } from '@shared/services/upload.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

@Injectable()
export class UploadEffects {
  uploadRequestEffect$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadActions.ActionTypes.UPLOAD_REQUEST),
        mergeMap((action) => {
          if (action.payload.file.status === UploadStatus.Failed) {
            return [new uploadActions.UploadAddFailed(action.payload.file)];
          }

          return this.uploadService
            .upload(action.payload.endpoint, action.payload.file.file, action.payload.queryParams)
            .pipe(
              take(1),
              map((event) =>
                UploadEffects.getActionFromHttpEvent(
                  event,
                  action.payload.file,
                  action.payload.isDocument,
                  action.payload.removeFile
                )
              ),
              catchError(() =>
                of(
                  new uploadActions.UploadFailure(
                    UploadEffects.getFailurePayload(
                      action.payload.file.id,
                      'DOCUMENTS.ERROR_UPLOADING_DOCUMENT'
                    )
                  )
                )
              )
            );
        })
      ),
    { useEffectsErrorHandler: false }
  );

  uploadCompleted$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadActions.ActionTypes.UPLOAD_COMPLETED),
        map((action: { isDocument: boolean; payload: UpdateStr<FileUpload> }) => {
          if (action.isDocument) {
            return documentActions.UploadDocumentSuccess({
              document: action.payload.changes.document,
            });
          }
          return new uploadActions.UploadNoop();
        })
      ),
    { useEffectsErrorHandler: false }
  );

  /**** Update Document classification to CV if user uploads new document and no other document is a cv ****/
  singleUploadCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.UploadDocumentSuccess),
      concatLatestFrom(({ document }) =>
        this.store.select(selectDocumentsByCandidateId(document.candidateId))
      ),
      filter(([_, documents]) => documents.length && !hasCv(documents)),
      switchMap(([{ document }, _]) => {
        const newDocument = { ...document, classification: DocumentType.Cv };
        return this.documentService.updatePutMethod(newDocument);
      }),
      map((document) => documentActions.UpdateDocumentSuccess({ document })),
      catchError((error: unknown) => of(documentActions.UpdateDocumentFailure({ payload: error })))
    )
  );

  private static getFailurePayload(id: string, error: string): UpdateStr<FileUpload> {
    return {
      id,
      changes: {
        percentage: 100,
        status: UploadStatus.Failed,
        progressMode: ProgressMode.Determinate,
        progressColor: ProgressColor.Warn,
        message: error,
      },
    };
  }

  private static getActionFromHttpEvent(
    event: HttpEvent<unknown>,
    payload: FileUpload,
    isDocument: boolean,
    removeFile: boolean
  ) {
    switch (event.type) {
      case HttpEventType.Sent: {
        return new uploadActions.UploadStarted({
          id: payload.id,
          changes: payload,
        });
      }
      case HttpEventType.UploadProgress: {
        return new uploadActions.UploadProgress({
          id: payload.id,
          changes: {
            percentage: Math.round((100 * event.loaded) / event.total),
            status: UploadStatus.Started,
            progressMode: ProgressMode.Determinate,
          },
        });
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === 200) {
          return new uploadActions.UploadCompleted(
            {
              id: payload.id,
              changes: {
                removeFile,
                percentage: 100,
                status: UploadStatus.Completed,
                progressMode: ProgressMode.Determinate,
                document: event['body'],
              },
            },
            isDocument
          );
        } else {
          return new uploadActions.UploadFailure(
            UploadEffects.getFailurePayload(payload.id, `Error ${event.status}`)
          );
        }
      }
      default: {
        return new uploadActions.UploadFailure(
          UploadEffects.getFailurePayload(payload.id, `Error ${event['status']}`)
        );
      }
    }
  }

  constructor(
    private uploadService: UploadService,
    private actions$: Actions<uploadActions.Actions>,
    private documentService: DocumentService,
    private store: Store
  ) {}
}

const hasCv = (documents: Document[]) => {
  return documents.some((document) => document.classification === DocumentType.Cv);
};
