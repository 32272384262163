import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { getMessageError } from '@core/models';
import {
  productSummaryApiActions,
  productSummaryPageActions,
} from '@features/product-summary/store/actions';
import { selectSelectedAccount, selectSelectedAccountId } from '@mkp/account/state';
import { cmsPageActions } from '@mkp/core/feature-cms/actions';
import { HomePageActions } from '@mkp/core/feature-home/actions';
import { selectMetadatasEntities } from '@mkp/metadata/state';
import { ProductOfferingActions } from '@mkp/product/feature-product-offering/actions';
import { PublicationStorePageActions } from '@mkp/publication/feature-publication-store/actions';
import { LOCAL_STORAGE_VOUCHER_KEY, ProductResource } from '@mkp/shared/data-access';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { TrackingService, TrackingUtilsService } from '@mkp/tracking/feature-tracking-old';
import { TrackingActions } from '@mkp/tracking/state/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  productOptionsApiActions,
  productOptionsPageActions,
} from '@product-options/store/actions';
import { selectProducts } from '@product-options/store/selectors/product-options.selectors';
import { accountTypeaheadActions } from '@store/actions';
import { State } from '@store/reducers';
import { selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { getVacancyById } from '@vacancy/store/selectors/vacancy.selectors';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ProductOptionsEffects {
  private readonly actions$ = inject(Actions);

  // remove coupon and force-refresh product-options on checkout
  readonly loadProductOptionsPostCheckout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productSummaryApiActions.productCheckoutSuccess),
      tap(({ cart }) => {
        if (cart.couponCode === localStorage.getItem(LOCAL_STORAGE_VOUCHER_KEY)) {
          localStorage.removeItem(LOCAL_STORAGE_VOUCHER_KEY);
        }
      }),
      this.fetchProductListOperator()
    );
  });

  // fetchProductsOperator is going to wait for BO to select a new account before triggering the call
  readonly loadProductOptionsOnUnselectAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountTypeaheadActions.unselectAccount),
      this.fetchProductListOperator()
    );
  });

  private readonly store = inject(Store<State>);

  // load product-options, only if there is NOT already a value in store
  readonly loadProductOptionsIfNotLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        productOptionsPageActions.enter,
        PublicationStorePageActions.enter,
        productSummaryPageActions.enter,
        ProductOfferingActions.enter,
        HomePageActions.enter,
        cmsPageActions.enter
      ),
      concatLatestFrom(() => this.store.select(selectProducts).pipe(map(Boolean))),
      filter(([, hasProducts]) => !hasProducts),
      map(([action]) => action),
      this.fetchProductListOperator()
    );
  });

  private readonly router = inject(Router);
  private readonly trackingService = inject(TrackingService);
  private readonly trackingUtilsService = inject(TrackingUtilsService);

  /***********************
   Salary Experimentation Effects
   *******************************/
  readonly productOptionsSalaryExperimentationStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productOptionsPageActions.salaryExperimentationStart),
      concatLatestFrom(({ vacancyId }) => [
        this.store.select(selectMetadatasEntities),
        this.store.select(getVacancyById(vacancyId)).pipe(filter(Boolean)),
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([_, metadata, vacancy, user, account]) =>
        this.trackingService.salaryExperimentationAddSalary({
          jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
          eventName: 'click',
          user,
          account,
          contentType: 'free_product_select_button',
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    );
  });

  readonly productOptionsSalaryExperimentationChooseFreeProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productOptionsPageActions.salaryExperimentationChooseFreeProduct),
      concatLatestFrom(({ vacancyId }) => [
        this.store.select(selectMetadatasEntities),
        this.store.select(getVacancyById(vacancyId)).pipe(filter(Boolean)),
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([_, metadata, vacancy, user, account]) =>
        this.trackingService.salaryExperimentationAddSalary({
          jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
          eventName: 'click',
          user,
          account,
          contentType: 'popup_salary_info_button',
        })
      ),
      map(TrackingActions.sendPureFrontendEvent),
      tap((data) => {
        // the "fragment" option will trigger a "Scroll" router-event, which will be caught in router.effects
        this.router.navigate(['vacancy', 'publish', `${data.job_id}`, 'create', 'edit'], {
          fragment: 'salaryFields',
        });
      })
    );
  });

  // force-refresh product-options when BO unselects an account
  readonly salaryExperimentationClosePopUp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productOptionsPageActions.salaryExperimentationClosePopup),
      concatLatestFrom(({ vacancyId }) => [
        this.store.select(selectMetadatasEntities),
        this.store.select(getVacancyById(vacancyId)).pipe(filter(Boolean)),
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([_, metadata, vacancy, user, account]) =>
        this.trackingService.salaryExperimentationAddSalary({
          jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
          eventName: 'click',
          user,
          account,
          contentType: 'popup_paid_product_button',
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    );
  });

  private readonly snackBarNotificationsService = inject(SnackbarService);

  readonly loadProductOptionsFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(productOptionsApiActions.loadProductOptionsFailure),
        tap(() => {
          this.snackBarNotificationsService.show('PRODUCT_OPTIONS.QUERY_ERROR_MSG', {
            state: ActionState.Error,
          });
        })
      );
    },
    { dispatch: false }
  );

  private readonly productResource = inject(ProductResource);

  private fetchProductListOperator<T>(): (source: Observable<T>) => Observable<Action> {
    return (source) =>
      source.pipe(
        concatLatestFrom(() => this.store.select(selectSelectedAccountId)),
        switchMap(([, selectedAccountId]) =>
          this.productResource.getAll(selectedAccountId).pipe(
            map((products) => productOptionsApiActions.loadProductOptionsSuccess({ products })),
            catchError((error: unknown) =>
              of(
                productOptionsApiActions.loadProductOptionsFailure({
                  error: getMessageError(error, 'ProductOptionsEffects'),
                })
              )
            )
          )
        )
      );
  }
}
