import { LanguageIso } from '@mkp/shared/data-access';
import {
  createEntityFeature,
  EntityFeatureState,
  FetchState,
  LoadingState,
} from '@mkp/shared/util-state';
import { createReducer, on } from '@ngrx/store';
import { appHeaderActions, languageEffectsActions } from '@store/actions';
import { onboardingActions, userSettingsActions } from '@store/actions/settings.actions';
import { authApiActions } from '@mkp/auth/actions';

export interface State extends EntityFeatureState<null, { message: string }> {
  language: LanguageIso | null;
  intercomHash: string | null;
  fetchState: FetchState<{ message: string }>;
  isNavigationCollapsed: boolean;
}

const {
  featureKey,
  initialState: initialFeatState,
  updaters,
  selectors,
} = createEntityFeature<State, 'settingsState'>('settingsState');

const initialState: State = {
  ...initialFeatState,
  language: null,
  intercomHash: null,
  fetchState: LoadingState.INIT,
  isNavigationCollapsed: false,
};

const reducer = createReducer<State>(
  initialState,
  on(
    onboardingActions.resendEmailVerificationStart,
    authApiActions.fetchAccessTokenSuccess,
    updaters.setLoading
  ),
  on(onboardingActions.resendEmailVerificationFailure, (state, { error }) =>
    updaters.setError(state, { error })
  ),
  on(onboardingActions.resendEmailVerificationSuccess, updaters.setLoaded),
  on(
    languageEffectsActions.appLanguageInitForUser,
    languageEffectsActions.appLanguageInitForVisitor,
    appHeaderActions.switchAppLanguage,
    (state, { languageIso }) => ({
      ...state,
      language: languageIso,
    })
  ),
  on(userSettingsActions.setUserIntercomHash, (state, { intercomHash }) => ({
    ...state,
    intercomHash,
  }))
);

export { featureKey, initialState, reducer, selectors };
