import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { b64toBlob } from '@app/shared/helpers/document.helper';
import { Document } from '@documents/models';
import { environment } from '@env/environment';
import { ResourceService } from '@shared/services/resource.service';
import { map, Observable } from 'rxjs';
import { DocumentContent } from '../models/document-content.model';

@Injectable({ providedIn: 'root' })
export class DocumentService extends ResourceService<Document> {
  constructor(
    httpClient: HttpClient,
    private readonly domSanitizer: DomSanitizer
  ) {
    super(httpClient, environment.api.core, 'ats/document/document');
  }

  public getPicture(photo: Document): Observable<SafeUrl> {
    return this.httpClient
      .get<DocumentContent>(`${environment.api.core}/ats/document/document/${photo.id}`)
      .pipe(
        map((document: DocumentContent) => b64toBlob(document.content, document.mimeType)),
        map((blob: Blob) => window.URL.createObjectURL(blob)),
        map((objectUrl: string) => this.domSanitizer.bypassSecurityTrustUrl(objectUrl))
      );
  }
}

@Injectable()
export class DocumentDownload extends ResourceService<any> {
  constructor(httpClient: HttpClient) {
    super(httpClient, environment.api.core, 'ats/document/document/downloadUrl');
  }
}
