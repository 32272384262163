import * as documentsActions from '@documents/store/actions/documents.actions';
import { createEntityFeature, EntityFeatureState, LoadingState } from '@mkp/shared/util-state';
import { createReducer, on } from '@ngrx/store';
import { Document } from '@documents/models';
import { isError } from '@mkp/shared/util-format';

export type State = EntityFeatureState<Document, { message: string }>;

const { featureKey, initialState, updaters, selectors, adapter } = createEntityFeature<
  State,
  'documentState'
>('documentState');

const reducer = createReducer(
  initialState,
  on(documentsActions.ResetDocument, updaters.reset),
  on(
    documentsActions.GetDocument,
    documentsActions.GetDocumentUrl,
    documentsActions.DeleteDocumentAndDeleteUpload,
    documentsActions.GetDocumentsFromCandidate,
    (state) => updaters.setLoading(state)
  ),
  on(
    documentsActions.GetDocumentUrlFailure,
    documentsActions.GetDocumentFailure,
    documentsActions.DeleteDocumentFailure,
    documentsActions.GetDocumentsFailure,
    documentsActions.UpdateDocumentFailure,
    (state, { payload }) =>
      updaters.setError(state, {
        error: { message: isError(payload) ? payload.message : 'Missing error' },
      })
  ),
  on(documentsActions.UploadDocumentSuccess, (state, { document }) =>
    updaters.createSuccess(state, { entity: document })
  ),
  on(documentsActions.GetDocumentUrlSuccess, (state, { selectedId }) => ({
    ...state,
    fetchState: LoadingState.LOADED,
    selectedId,
  })),
  on(
    documentsActions.DeleteDocument,
    // documentsActions.DeleteDocumentSuccess,  use Optimistic way
    (state, { id }) => updaters.deleteSuccess(state, { id })
  ),
  on(documentsActions.GetDocumentSuccess, (state, { document }) => ({
    ...updaters.createSuccess(state, { entity: document }),
    selectedId: state.selectedId,
  })),
  on(documentsActions.GetDocumentsSuccess, (state, { documents }) =>
    updaters.getListSuccess(state, { entities: documents })
  ),
  on(documentsActions.UpdateDocumentSuccess, (state, { document }) =>
    updaters.updateSuccess(state, { updateEntity: { id: document.id, changes: document } })
  )
);

export { featureKey, selectors, reducer, initialState };
