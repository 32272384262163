import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BoDashboardService } from '@app/core/services/bo-dashboard.service';
import { Vacancy } from '@app/features/vacancy/models/vacancy.model';
import { AppConfig } from '@config/app.config';
import { MessageError, getMessageError } from '@core/models';
import { UUID } from '@mkp/shared/data-access';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { BoDashboardActions } from '@mkp/user/feature-bo-dashboard/actions';
import { vacancyEditActions } from '@mkp/vacancy/feature-vacancy-edit/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UtilsHelpers } from '@shared/helpers/utils.helpers';
import { QueryOptions } from '@shared/models';
import * as fromRoot from '@store/index';
import { VacancyResource } from '@vacancy/resources/vacancy.resource';
import { VacancySanitizerProxyService, VacancyService } from '@vacancy/services';
import {
  listVacancyActions,
  vacancyExistsGuardActions,
  vacancyFormPageActions,
  vacancyWithoutPaidProductGuardActions,
} from '@vacancy/store/actions';
import * as VacancyActions from '@vacancy/store/actions/vacancy.actions';
import {
  Observable,
  OperatorFunction,
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { VacancySerializer } from '../../interfaces/vacancy-serializer.interface';
import { vacancyApiActions } from '../actions/vacancy-api.actions';

@Injectable()
export class VacancyEffects {
  createVacancy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vacancyEditActions.createVacancy),
        switchMap(({ vacancy }) =>
          this.sanitizerSvc.create(vacancy).pipe(
            tap(() => this.notify('VACANCY_PAGE.MESSAGE.CREATE')),
            tap((vacancy) => this.boDashboardService.addVacancy(vacancy)),
            map((vacancy) => VacancyActions.CreateVacancySuccess({ vacancy })),
            catchError((error: unknown) =>
              of(VacancyActions.CreateVacancyFailure({ error: getVacancyError(error) }))
            )
          )
        )
      ),
    { useEffectsErrorHandler: false }
  );

  // From Edit page
  updateVacancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vacancyFormPageActions.updateVacancy),
      switchMap(({ vacancy }) => {
        const serializedVacancy = VacancySerializer.toJson(vacancy);
        return this.sanitizerSvc
          .patchValue<Vacancy>({ ...serializedVacancy }, { maxRetry: 1 })
          .pipe(
            this.updateVacancySuccessOperator(),
            map(({ id, changes }) => {
              return vacancyApiActions.updateVacancySuccess({ update: { id, changes } });
            }),
            catchError((error: unknown) =>
              of(
                vacancyApiActions.updateVacancyFailure({
                  vacancyId: vacancy.id,
                  error: getVacancyError(error),
                })
              )
            )
          );
      })
    )
  );

  listVacancies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listVacancyActions.load),
      this.fetchVacancies(
        vacancyApiActions.listVacanciesSuccess,
        vacancyApiActions.listVacanciesFailure
      )
    )
  );

  loadMoreVacancies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listVacancyActions.loadMore),
      this.fetchVacancies(
        vacancyApiActions.loadMoreVacanciesSuccess,
        vacancyApiActions.loadMoreVacanciesFailure
      )
    )
  );

  /*** Get Vacancy ***/
  getVacancy$ = createEffect(
    () =>
      this.actions$.pipe(ofType(VacancyActions.GetVacancy)).pipe(
        mergeMap(({ vacancyId }) => {
          return this.vacancySvc.read(vacancyId).pipe(
            map((vacancy) => VacancyActions.GetVacancySuccess({ vacancy })),
            catchError((error: unknown) => {
              return of(
                VacancyActions.GetVacancyFailure({ vacancyId, error: getVacancyError(error) })
              );
            })
          );
        })
      ),
    { useEffectsErrorHandler: false }
  );

  readonly loadVacancyFromGuard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        vacancyExistsGuardActions.canActivate,
        vacancyWithoutPaidProductGuardActions.canActivate
      ),
      concatMap(({ vacancyId }) => {
        return this.vacancyResource.getVacancy(vacancyId).pipe(
          map((vacancy) => vacancyApiActions.loadVacancyFromGuardSuccess({ vacancy })),
          catchError((error: unknown) =>
            of(
              vacancyApiActions.loadVacancyFromGuardFailure({
                vacancyId,
                error: getVacancyError(error),
              })
            )
          )
        );
      })
    );
  });

  readonly loadVacanciesByIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoDashboardActions.enter),
      filter(({ vacancyIds }) => vacancyIds.length > 0),
      switchMap(({ vacancyIds }) => {
        const filter = getFilterFromVacancyIds(vacancyIds);
        return this.vacancySvc.list({ filter }).pipe(
          map(({ _embedded }) =>
            vacancyApiActions.loadVacanciesByIdsSuccess({ vacancies: _embedded?.results ?? [] })
          ),
          catchError((error: unknown) =>
            of(vacancyApiActions.loadVacanciesByIdsFailure({ error: getVacancyError(error) }))
          )
        );
      })
    );
  });

  // From Listing Page
  deleteDraftVacancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listVacancyActions.deleteDraftVacancy),
      mergeMap(({ id }) =>
        this.vacancyResource.deleteVacancy(id).pipe(
          take(1),
          map((id) => vacancyApiActions.deleteDraftVacancySuccess({ id })),
          catchError((error: unknown) =>
            of(vacancyApiActions.deleteDraftVacancyFailure({ error: getVacancyError(error) }))
          )
        )
      )
    )
  );

  deleteDraftVacancySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vacancyApiActions.deleteDraftVacancySuccess),
        tap(() => this.notify(`VACANCY_PAGE.MESSAGE.DELETE_DRAFT`)),
        tap(() => this.router.navigateByUrl(AppConfig.routes.vacancy))
      ),
    { dispatch: false }
  );

  deleteDraftVacancyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vacancyApiActions.deleteDraftVacancyFailure),
        tap(() => this.notify(`VACANCY_PAGE.MESSAGE.ERROR_DELETE_DRAFT`, ActionState.Error))
      ),
    { dispatch: false }
  );
  constructor(
    private readonly actions$: Actions,
    private readonly vacancySvc: VacancyService,
    private readonly notificationSvc: SnackbarService,
    private readonly translateSvc: TranslateService,
    private readonly sanitizerSvc: VacancySanitizerProxyService,
    private readonly _utilsHelpers: UtilsHelpers,
    private readonly store: Store<fromRoot.State>,
    private readonly vacancyResource: VacancyResource,
    private readonly router: Router,
    private readonly boDashboardService: BoDashboardService
  ) {}

  private notify(key: string, state = ActionState.Success): void {
    const message = this.translateSvc.instant(key);
    this.notificationSvc.show(message, { state });
  }

  private updateVacancySuccessOperator(): (
    source: Observable<Vacancy>
  ) => Observable<{ id: UUID; changes: Partial<Vacancy> }> {
    return (source) =>
      source.pipe(
        tap((vacancy) => this.boDashboardService.addVacancy(vacancy)),
        map((vacancy: Vacancy) => {
          const changes = this._utilsHelpers.removeKeys(vacancy, [
            'applicationCount',
            'newApplicant',
          ]);
          return { id: vacancy.id, changes };
        })
      );
  }

  private fetchVacancies(
    successAction: FetchVacanciesSuccessActionCreator,
    failureAction: FetchVacanciesFailureActionCreator
  ): OperatorFunction<{ query: QueryOptions }, FetchVacanciesAction> {
    return switchMap(({ query }) => {
      const options = query || { limit: 25 };
      return this.vacancySvc.list(options).pipe(
        map(({ _embedded, _links, totalCount, filter }) =>
          successAction({
            vacancies:
              _embedded?.results.map((vacancy) => VacancySerializer.fromJson(vacancy)) ?? [],
            _links,
            totalCount,
            filter,
          })
        ),
        catchError((error: unknown) => of(failureAction({ error: getVacancyError(error) })))
      );
    });
  }
}

type FetchVacanciesSuccessActionCreator =
  | typeof vacancyApiActions.listVacanciesSuccess
  | typeof vacancyApiActions.loadMoreVacanciesSuccess;
type FetchVacanciesFailureActionCreator =
  | typeof vacancyApiActions.listVacanciesFailure
  | typeof vacancyApiActions.loadMoreVacanciesFailure;
type FetchVacanciesAction = ReturnType<
  FetchVacanciesSuccessActionCreator | FetchVacanciesFailureActionCreator
>;

const getVacancyError = (error: unknown): MessageError => getMessageError(error, 'VacancyEffects');

const getFilterFromVacancyIds = (vacancyIds: Vacancy['id'][]): string =>
  `id==${vacancyIds.join(`,id==`)}`;
