import { Environment } from './environment.model';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('../../../../package.json');

export const environment: Environment = {
  production: true,
  name: 'prod',
  version: packageJson.version,
  hash: packageJson.hash,
  ngrxDevTools: [],
  apolloDevTools: false,
  url: 'https://jobcloud.ai',
  translationHash: new Date().getTime().toString(),
  network: {
    jobs: 'https://www.jobs.ch',
    jobcloud: 'https://www.jobcloud.ch',
    js24: 'https://www.jobscout24.ch',
    jobup: 'https://www.jobup.ch',
  },
  api: {
    core: 'https://api.jobcloud.ai',
    ats: 'https://api.jobcloud.ai/ats',
    internalATS: 'https://ats.jobcloud.ai',
    metadata: 'https://metadata-api.jobcloud.services/api/v1/meta',
    media: 'https://media.jobcloud.ai/media',
    ecom: 'https://ecommerce.jobcloud.ai/shop-api',
    middleware: 'https://middleware.jobcloud.ai',
  },
  auth: {
    clientId: 'uqjkIuwJ5varHIm2wvee3q59EUkrP4T5',
    domain: 'auth.jobcloud.ai',
    audience: 'marketplace-apis',
    scope: 'openid profile email offline_access',
    connection: 'Username-Password-Authentication',
    redirectUri: 'https://jobcloud.ai',
    responseType: 'code',
    skipRedirectCallback: true,
    useRefreshTokens: true,
  },
  sentry: {
    target: 'jobcloud.ai',
    tracesSampleRate: 0.2,
  },
};

export const auth = environment.auth;
