import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppConfig } from '@config/app.config';

@Injectable({
  providedIn: 'root',
})
export class BoUserGuard {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canLoad(): Observable<boolean | UrlTree> {
    return this.hasCustomerServiceDashboard();
  }

  private hasCustomerServiceDashboard(): Observable<boolean | UrlTree> {
    return this.store
      .select(selectIsBoUser)
      .pipe(map((isBoUser) => isBoUser || this.router.parseUrl(AppConfig.routes.dashboard)));
  }
}
