import { animate, style, transition, trigger } from '@angular/animations';

export const ngIfInOutAnimation = (
  name: string,
  animateInTiming: string,
  animateOutTiming: string | null
) =>
  trigger(name, [
    transition(':enter', [style({ opacity: 0 }), animate(animateInTiming, style({ opacity: 1 }))]),
    transition(':leave', [
      style({ opacity: 1 }),
      ...(animateOutTiming ? [animate(animateOutTiming, style({ opacity: 0 }))] : []),
    ]),
  ]);

export const ngIfTranslateYAnimation = (
  name: string,
  animateInTiming: string,
  animateOutTiming: string | null,
  direction: 'up' | 'down'
) =>
  trigger(name, [
    transition(':enter', [
      style({ transform: direction === 'down' ? 'translateY(-100%)' : 'translateY(100%)' }),
      animate(animateInTiming, style({ transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0)' }),
      ...(animateOutTiming
        ? [
            animate(
              animateOutTiming,
              style({ transform: direction === 'down' ? 'translateY(-100%)' : 'translateY(100%)' })
            ),
          ]
        : []),
    ]),
  ]);
export const ngIfTranslateYDelayedAnimation = (
  name: string,
  animateInTiming: string,
  animateOutTiming: string | null,
  animateInTimingDelayed: string,
  animateOutTimingDelayed: string | null
) =>
  trigger(name, [
    transition(':enter', [
      style({
        opacity: 0,
        height: 0,
      }),
      animate(animateInTiming, style({ height: '*' })),
      animate(animateInTimingDelayed, style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({
        height: '*',
        opacity: 1,
      }),
      ...(animateOutTiming ? [animate(animateOutTiming, style({ opacity: 0 }))] : []),
      ...(animateOutTimingDelayed ? [animate(animateOutTimingDelayed, style({ height: 0 }))] : []),
    ]),
  ]);

export const ngIfInOutAnimationNavigationHeader = ngIfInOutAnimation(
  'inOutAnimation',
  '.4s .2s ease-in',
  null
);

export const ngIfInOutAnimationDrawerBackdrop = ngIfInOutAnimation(
  'inOutAnimation',
  '.4s .2s ease-in',
  null
);

export const ngIfInOutAnimationNavigationCompany = ngIfInOutAnimation(
  'inOutAnimation',
  '.4s .2s ease-in',
  null
);

export const ngIfTopDownAnimationStickyHeader = ngIfTranslateYAnimation(
  'topDownAnimation',
  '.3s ease-in-out',
  '.3s ease-in-out',
  'down'
);

export const ngIfTopDownAnimationSalary = ngIfTranslateYDelayedAnimation(
  'topDownAnimation',
  '.2s ease-in-out',
  '.2s ease-in-out',
  '.3s ease-in-out',
  '.3s ease-in-out'
);

export const ngIfTopUpAnimationSGdprBanner = ngIfTranslateYAnimation(
  'topUpAnimation',
  '.4s .2s ease-in-out',
  null,
  'up'
);
