import { Account } from '@mkp/account/data-access';
import { createActionGroup, props } from '@ngrx/store';

export const BoDashboardActions = createActionGroup({
  source: 'Bo Dashboard',
  events: {
    Enter: props<{ vacancyIds: string[]; accountIds: string[] }>(),
    'Init Dropdown': props<{ accountIds: string[] }>(),
    'Select Account': props<{ account: Account }>(),
    'Select Account From Vacancy': props<{ account: Account }>(),
  },
});
