export interface ATSDocument {
  id: string;
  classification: DocumentType;
  fileName: string;
  fileSize: number;
  mimeType: string;
  content: string;
  visibleToCandidate: boolean;
  transferType: number;
  candidateId?: string;
  applicationId?: string;
  messageAttachmentId?: string;
  userId?: string;
  mandatorProfileId?: string;
  thumbnailId?: string;
  mandatorId?: string;
  createdOn: Date;
}

export interface Document extends ATSDocument {
  skipActivityLog: boolean;
  parseAndReindex: boolean;
}

export interface RawAtsDocument {
  data: {
    documents: Document[];
  };
  errors?: unknown;
}

export enum DocumentType {
  None = 0,
  Cv = 1,
  Photo = 2,
  Other = 3,
  OriginalRequest = 4,
  Thumbnail = 5,
}

export const isPhoto = (document: Document): boolean =>
  document.classification === DocumentType.Photo;

export enum DocumentParsingType {
  CANDIDATE_CREATION = 2,
}
